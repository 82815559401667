<template>
  <div class="content">
    <h2>{{huiyis[meeting_id]}}</h2>
    <h3>请输入报名手机号进行签到</h3>
    <van-icon name="arrow-down" />
    <van-field name="diqu" v-model="diqu" 
          required 
          label="地区" 
          placeholder="请选择地区"  
          readonly
          clickable
          :rules="[{ required: true, message: '请选择称呼' }]"
          @click="showDiquPicker = true"
          right-icon="arrow"
        />
    <van-password-input
      :value="phone"
      :length="phone_len"
      :mask="false"
      :focused="showKeyboard"
      info="请输入国家地区以外的手机号码"
      :error-info="errorInfo"
      @focus="showKeyboard = true"
      />
    <van-number-keyboard
      v-model="phone"
      :show="showKeyboard"
      @blur="showKeyboard = false"
      />
    <div class="sign_btn">
      <van-button  
        round 
        block
        type="primary"
        :disabled="showBtn"
        @click="onSign">签    到</van-button>
      
    </div>
    <div class="sign_btn">
      <van-button  
        round 
        block
        plain
        hairline
        type="primary"
        @click="baoming">立即报名签到</van-button>
      
    </div>
    <van-popup v-model="showDiquPicker" round position="bottom">
      <van-picker
        title="地区"
        show-toolbar
        :columns="diqus"
        @confirm="onDiquConfirm"
        @cancel="showDiquPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant';

export default {
  name: 'Sign',
  data() {
    return {
      phone: '',
      showKeyboard: true,
      errorInfo: '',
      showBtn: true,
      meeting_id: '',
      meeting_name: '',
      phone_len: 11,
      diqu: '中国大陆',
      showDiquPicker: false,
      diqus: ['中国大陆','澳门','香港','台湾'],
      huiyis: ['大会','中医药健康产业融合发展大会','2021中华医药产业发展澳门论坛','中医针灸国际合作及产业发展论坛','中医女性健康论坛']
    };
  },
  created() {
    this.meeting_id=this.$route.query.meeting_id
    this.meeting_name=this.$route.query.meeting_name
  },
  watch: {
    phone(phone) {
      if (phone.length === 11 && this.diqu === "中国大陆" ) {
        if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(phone))){ 
          this.errorInfo = '请正确输入手机号码';
        }else {
          this.errorInfo = '';
          this.showBtn = false;
        }
      } else if (phone.length === 8 && this.diqu !== "中国大陆") {
        if(!(/[1][3-8]\d{9}$|([6|9])\d{7}$|[0][9]\d{8}$|6\d{5}$/.test(phone))){ 
          this.errorInfo = '请正确输入手机号码';
        }else {
          this.errorInfo = '';
          this.showBtn = false;
        }
      } else {
        this.errorInfo = '';
        this.showBtn = true;
      }
    },
  },
  methods: {
    onSign() {
      var data = {
        "meeting_id": this.meeting_id,
        "meeting_name": this.meeting_name,
        "phone": this.phone
        }
      var _this = this
      _this.$axios.post('/api/SignMeeting', data)
      .then((res) => {
        if (res.data.code == "2000") {
          Dialog({ message: res.data.message });
          Dialog.confirm({
            title: '请确认手机号',
            message: res.data.message,
            cancelButtonText: "报名签到",
            cancelButtonColor: 'red',
            confirmButtonColor: 'grey'
          })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            this.baoming();
          });
        }else if (res.data.code == "0") {
          _this.$router.push({
            name: "SignSuccess",
            params:{}
          })
        }else {
          Dialog({ message: res.data.message });
        }
      })
      .catch((error) => { // 请求失败处理
        Dialog({ message: error });
      });
    },
    baoming() {
      this.$router.push({
        name: "baoming",
        query:{
          meeting_id: this.meeting_id,
          meeting_name: this.huiyis[this.meeting_id],
          hexiao_type: 1,
        }
      })
    },
    onDiquConfirm(value) {
      this.diqu = value;
      switch(value) {
          case "中国大陆":
              this.phone_len = 11
              break;
          case "澳门":
              this.phone_len = 8
              break;
          case "香港":
              this.phone_len = 8
              break;
          case "台湾":
              this.phone_len = 8
              break;
          default:
              this.phone_len = 11
      } 
      this.showDiquPicker = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
    text-align: center;
}
.sign_btn {
    margin-top: 30px;
    margin-left: 60px;
    margin-right: 60px;
}
</style>
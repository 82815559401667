<template>
  <div>
    <van-nav-bar
      title="联系我们"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      
        <div class="rich_media_content " id="js_content" style="visibility: visible;">
                    

                    
                    
                    
                    <p><span style="font-size: 16px;">中国医药保健品进出口商会</span></p><p><span style="font-size: 16px;">企业参展咨询：马云轩</span></p><p><span style="font-size: 16px;">电话：010-58036335</span></p><p><span style="font-size: 16px;">邮箱：mayunxuan@cccmhpie.org.cn</span></p><p><span style="font-size: 16px;">&nbsp;</span></p><p><span style="font-size: 16px;">观众参观咨询：邹雨昕</span></p><p><span style="font-size: 16px;">电话：010-58036345</span></p><p><span style="font-size: 16px;">邮箱：zouyuxin@cccmhpie.org.cn</span></p><p><span style="font-size: 16px;">&nbsp;</span></p><p><span style="font-size: 16px;">商务团组咨询：史静</span></p><p><span style="font-size: 16px;">电话：010-58036312</span></p><p><span style="font-size: 16px;">邮箱：shijing@cccmhpie.org.cn</span></p><p><span style="font-size: 16px;">&nbsp;</span></p><p><span style="font-size: 16px;">市场、会议合作：涂晓颖</span></p><p><span style="font-size: 16px;">电话：010-58036315</span></p><p><span style="font-size: 16px;">邮箱：tuxiaoying@cccmhpie.org.cn</span></p><p><br></p>
                </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'lianxi',
  data() {
    return {
       
    };
  },
  created() {
    let code = this.getUrlCode().code; //获取url参数code
    console.log("code:" + code);
    if(code){ //拿到code， code传递给后台接口换取opend
      console.log("code:" + code);
    }else{
      this.getCodeApi("STATE");
    }
  },
  methods: {
    getUrlCode() {
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    getCodeApi(state) {
      //获取code
      let urlNow = encodeURIComponent(window.location.href);
      let scope = "snsapi_base"; //snsapi_userinfo   //静默授权 用户无感知
      let appid = "wxab3a2c0dd18c184f";
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },
    back() {
      this.$router.go(-1);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}

</style>

<template>
  <div>
    <van-nav-bar
      title="签到码"
      left-text="首页"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <div v-if="this.showWaning">
        <p>没有找到您的注册信息</p>
      </div>
      <div v-else>
        <div class="qrCode" ref="qrCodeDiv"></div>
        <div class="name">{{ piaoInfo.user_name}}</div>
        <div class="zhiwei">{{ piaoInfo.user_zhiwei}}</div>
        <div class="company">{{ piaoInfo.user_company}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getPiaoInfo } from "@/api/zhuce";

export default {
  name: "myQR",
  data() {
    return {
      phone: '',
      piaoInfo: {},
      showWaning: false,
      ex_id: ''
    };
  },
  created() {
    this.ex_id = this.$route.query.ex_id
  },
  mounted() {
    var that = this
    that.phone = localStorage.getItem('phone')
    var data = { phone: this.phone, ex_id: this.ex_id }
    getPiaoInfo(data).then((res) => {
      if (res.code == '10000') {
        if (res.data != null) {
          that.piaoInfo = res.data
          console.log(that.piaoInfo)
          that.bindQrcode(that.piaoInfo)
        } else {
          that.showWaning = true
        }
      } 
    })
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home",
        query: {ex_id:this.ex_id},
      });
    },
    bindQrcode(piaoInfo) {
      console.log("bind",this.piaoInfo)
      new QRCode(this.$refs.qrCodeDiv, {
        text: piaoInfo.piao_id+"|"+piaoInfo.user_name,
        width: 200,
        height: 200,
        colorDark: "#333333",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px auto;
  text-align: center;
}
.qrCode {
  width: 200px;
  height: 200px;
  margin: 0px auto;
}
.name {
  font-size: 36px;
  line-height: 40px;
  margin-top: 32px;
}
.company {
  font-size: 18px;
  margin-top: 8px;
}
.zhiwei {
  font-size: 24px;
  margin-top: 16px;
}
</style>

<template>
  <div>
    <van-nav-bar
      title="合作项目"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>资料确认</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <div style="text-align: center;">
        <div style="margin-top: 2rem">
            <van-icon name="checked" color="#07c160" size="6rem"/>
        </div>
        <p style="font-size: 20px; margin: 2rem">您的合作申请我们已经收到，3个工作日之内，会有相关商务人员跟您取得联系，敬请留意。</p>
    </div>
    <div class="homeBtn">
      <van-button icon="wap-home-o" type="primary" url="/wx/home?ex_id=1">首 页</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hezuoSuccess',
  data() {
    return {
      active: 2,
      hexiao_type: 0, //0不核销，1立即核销
      meeting_id:'',
    };
  },
  created() {
    window.scrollTo(0,0);
  },
  methods: {
    sign() {
    //   window.open("/page/Sign?meeting_id="+this.meeting_id)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-image .van-empty__image {
    width: 90px;
    height: 90px;
}
.homeBtn {
  width: 100px;
  margin: auto;
}
</style>

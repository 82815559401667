import request from '@/utils/request'

export function addOrder(data) {
  return request({
    url: '/api/wx/order/addOrder',
    method: 'post',
    data: data
  })
}
  
export function getOrderInfo(order_id) {
  return request({
    url: '/api/wx/order/getOrderInfo',
    method: 'get',
    params: { order_id }
  })
}

export function getOrderList(data) {
  return request({
    url: '/api/wx/order/getOrderList',
    method: 'post',
    data: data
  })
}

export function checkShouji(data) {
  return request({
    url: '/api/wx/order/checkShouji',
    method: 'get',
    params: data
  })
}

export function checkEmail(data) {
  return request({
    url: '/api/wx/order/checkEmail',
    method: 'get',
    params: data
  })
}


export function getPiaoInfo(data) {
  return request({
    url: '/api/wx/order/getPiaoInfo',
    method: 'get',
    params: data
  })
}

<template>
  <div>
    <van-nav-bar
      title="报名参加"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>同行人报名</van-step>
      <van-step>确认同行人</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-cell-group name="baseinfo" title="购票信息">
      <van-collapse v-model="activeNames">
        <van-collapse-item name="1">
          <template #title>
            <div>{{ name }} <van-tag plain type="success">{{ piao }}</van-tag></div>
          </template>
          公司：{{ company }} <br>
          职位：{{ zhiwei }} <br>
          手机：{{ shouji }}<br>
          邮箱：{{ email }}<br>
        </van-collapse-item>
        <van-collapse-item  v-for="(item, index) in this.tongxing" :key="index"  :name="index">
            <template #title>
              <div>{{ item.name }} <van-tag plain type="success">{{item.piao}}</van-tag></div>
            </template>
            公司：{{ company }} <br>
            职位：{{item.zhiwei}} <br>
            手机：{{item.shouji}}<br>
            邮箱：{{item.email}}<br>
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    <div>
      <van-row>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="default" @click="onEdit">修改</van-button>
          </div>
        </van-col>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="primary" :disabled="nextLoding" @click="onNext">下一步</van-button>
          </div>
        </van-col>
      </van-row>
    </div>

  </div>
</template>

<script>
import { addOrder } from '@/api/zhuce'

export default {
  name: 'zhuceTongxing',
  data() {
    return {
      active: 2,
      piao: '',
      piaos: ['参会票'],
      showChenghuPicker: false,
      name: '',
      company: '',
      zhiwei: '',
      shouji: '',
      email: '',
      coll: ['1','2'],
      xingqu:[],
      yewuleibie:[],
      yewuleibieTxt: ['biotech','Pharma','院校/科研机构/医疗机构','CRO/CMO/CDMO','原辅料/试剂/耗材/仪器/设备/冷链/系统','政府部门/商协会/国际组织','数据/咨询/律所/投融资机构','媒体','社会观众及其他'],
      xingquTxt: ['小分子药物','抗体药物','ADC药物','细胞治疗','基因治疗','新型疫苗','mRNA'],
      tongxing: [],
      brand: '',
      ip: '',
      city: '',
      partner_id: '',
      sub_user_id: '',
      activeNames:  ['1',],
      nextLoding: false
    };
  },
  created() {
    window.scrollTo(0,0);
    this.ip = sessionStorage.getItem('ip')
    this.city = sessionStorage.getItem('cname')

    // 引用mobile-detect文件
    var MobileDetect = require('mobile-detect');
    var md = new MobileDetect(navigator.userAgent);
    // 品牌
    this.brand = md.mobile();
    if (this.brand == null) {
      this.brand = "电脑PC"
    }

    console.log(this.$route.params)
    this.piao = this.$route.params.piao;
    this.name = this.$route.params.name;
    this.company = this.$route.params.company;
    this.zhiwei = this.$route.params.zhiwei;
    this.shouji = this.$route.params.shouji;
    this.email = this.$route.params.email;
    this.xingqu = this.$route.params.xingquGroup;
    this.yewuleibie = this.$route.params.yewuleibieGroup
    this.partner_id=this.$route.params.partner_id
    this.sub_user_id=this.$route.params.sub_user_id
    this.tongxing = this.$route.params.tongxing
  },
  methods: {
    sign() {
    //   window.open("/page/Sign?meeting_id="+this.meeting_id)
    },
    onEdit() {
      var data = this.$route.params
      this.$router.push({
        name: "zhuceAdd", //这个path就是你在router/index.js里边配置的路径
        params: data,
      });
    },
    onNext() {
      this.nextLoding = true
      var data = this.$route.params
      addOrder(data).then((res) => {
        if (res.code == '10000') {
          localStorage.removeItem("piao");
          localStorage.removeItem("name");
          localStorage.removeItem("company");
          localStorage.removeItem("zhiwei");
          localStorage.removeItem("shouji");
          localStorage.removeItem("email");
          localStorage.removeItem("xingqu");
          localStorage.removeItem("yewuleibie");
          localStorage.removeItem("tongxing");
          this.nextLoding = false
          this.$router.push({
            name: "zhuceSuccess",
            query:{ 'order_id': data.order_id
            }
          })
        } 
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>

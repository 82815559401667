<template>
  <div>
    <van-nav-bar
      title="合作申请"
      left-text="修改"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>资料确认</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-cell-group name="baseinfo" title="填写资料">
      <van-field v-model="name" name="name"
        readonly
        label="姓名" 
      />

      <van-field v-model="company" name="company"
        readonly
        label="公司/机构" 
      />

      <van-field v-model="zhiwei"  name="zhiwei"
        readonly
        label="职位" 
      />

      <van-field v-model="shouji" name="shouji"
        readonly
        type="tel" 
        label="手机号" 
      />

      <van-field v-model="email"  name="email"
        readonly
        label="邮箱" 
      />

    </van-cell-group>
    <van-cell-group name="baseinfo">
      <van-collapse v-model="coll">
        <van-collapse-item title="合作项目" name="1">
          <li v-for="item in hezuoxiangmu" :key="item">
            {{ hezuoxiangmuTxt[item] }}
          </li>
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    
    <div>
      <van-row>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="default" @click="onEdit">修改</van-button>
          </div>
        </van-col>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="primary" @click="onNext">下一步</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { addhezuo } from '@/api/hezuo'

export default {
  name: 'AttendConfirm',
  data() {
    return {
      meeting_id: '',
      meeting_name: '',
      name: '',
      company: '',
      zhiwei: '',
      shouji: '',
      email: '',
      active: 1,
      coll: ['1','2'],
      xingqu:[],
      hezuoxiangmu:[],
      hezuoxiangmuTxt: ['赞助（广告、印刷品、会议物资、礼品）','演讲及圆桌讨论','展位','冠名','其他'],
    };
  },
  created() {
    window.scrollTo(0,0);
    this.name = this.$route.params.name;
    this.company = this.$route.params.company;
    this.zhiwei = this.$route.params.zhiwei;
    this.shouji = this.$route.params.shouji;
    this.email = this.$route.params.email;
    this.hezuoxiangmu = this.$route.params.hezuoxiangmu;

  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onNext() {
      let data = this.$route.params ;
      addhezuo(data).then((res) => {
        if (res.code == '10000') {
          this.$router.push({
            name: "hezuoSuccess",
            params:{
            }
          })
          localStorage.removeItem('hname');
          localStorage.removeItem('hcompany');
          localStorage.removeItem('hzhiwei');
          localStorage.removeItem('hshouji');
          localStorage.removeItem('hemail');
          localStorage.removeItem('hhezuoxiangmu');
        } else {
          Dialog("异常");
        }
      })
    },
    onEdit() {
      this.$router.go(-1);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <div>
    <div class="login_head"><h2>登录</h2></div>
    <van-form @submit="login">
      <van-cell-group inset>
        <van-field
          v-model="phone"
          required
          label="手机号"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <van-field
          v-model="sms"
          center
          required
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请输入短信验证码' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              native-type="button"
              @click="start"
              :loading="loading"
              :loading-text="loadingTxt"
              >发送验证
            </van-button>
            <van-count-down
              :hidden="cdHidden"
              ref="countDown"
              :time="60000"
              :auto-start="false"
              format="ss 秒"
              @finish="finish"
            />
          </template>
        </van-field>
      </van-cell-group>
      <div class="login_btn">
        <van-button round block type="primary" native-type="submit"
          >登录</van-button
        >
      </div>

    </van-form>

    <div class=""></div>
  </div>
</template>

<script>
import { getSmsCode, login } from '@/api/user'
import { getExpoInfoByHost } from '@/api/expo'


// function getUrlKey(name){//获取url 参数
//   return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||['',""])[1].replace(/\+/g,'%20'))||null;
// }
 
// function getCodeApi(state){//获取code 
//  let urlNow=encodeURIComponent(window.location.href);
//  let scope='snsapi_base'; //snsapi_userinfo //静默授权 用户无感知
//  let appid='wx263daaa6b70cf26b';
//  let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
//  window.location.replace(url);
// }
export default {
  data() {
    return {
      redirect: "",
      otherQuery: {},
      phone: "",
      sms: "",
      loading: false,
      loadingTxt: "",
      time: "",
      cdHidden: true,
      openid: '',
      re_openid: '',
      ex_id: ''
    };
  },

  created() {
    this.redirect = this.$route.query.redirect
    this.ex_id = this.$route.query.ex_id
    // if(this.isWechat()) {
    //   let code=getUrlKey("code");//获取url参数code
    //   if(code){ //拿到code， code传递给后台接口换取opend
    //     getOpenid(code).then(res=>{
    //       console.log(res.data.info.openid);
    //       this.openid = res.data.info.openid
    //     })
    //   }else{
    //     getCodeApi("123");
    //   }
    // } 
    
  },

  methods: {
    sendsms() {
      this.loading = true
      this.loadingTxt = "已发送"
      getSmsCode(this.phone).then((res) => {
        console.log(res.data)
        // Dialog.alert({
        //   title: '验证码',
        //   message: res.data,
        // }).then(() => {
        //   // on close
        // });
      })
    },
    start() {
      this.$refs.countDown.start();
      this.sendsms();
    },
    finish() {
      this.loading = false;
      this.reset();
    },
    reset() {
      this.$refs.countDown.reset();
    },
    onSubmit(values) {
      console.log(values);
    },
    login() {
      
      let data = {
        phone: this.phone,
        code: this.sms,
        openid: this.openid,
        re_openid: this.re_openid
      }
      login(data).then((res) => {
        if (res.code == '10000') {
          localStorage.setItem('user_id', res.data.user_id)
          localStorage.setItem('phone', data.phone)
          localStorage.setItem('openid', data.openid)
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
        }
      })
    },
    isWechat() {
      let ua = window.navigator.userAgent.toLowerCase();
      if ((/MicroMessenger/i).test(ua)) {
        return true;
      } else {
        return false;
      }
    },
    backHome( ) {
      let url = window.location.hostname;
      getExpoInfoByHost(url).then((res) => {
        if (res.code == '10000') {
          this.ex_id = res.data.ex_id;
          this.$router.push({
            name: "Home",
            query: {ex_id: this.ex_id},
          });
        } else {
          console.log("异常");
        }
      })
      
    }
  },

};
</script>

<style scoped>
.login_head {
  text-align: center;
}
.login_btn {
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 60px;
}
.back_home {
  text-align: center;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #666666;
  width: 100px;
}
</style>

<template>
  <div>
    <van-nav-bar
      title="展商名单"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <div
        class="rich_media_content"
        id="js_content"
        style="visibility: visible"
      >
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/a.webp" />
        </div>
        <p>
          <span style="font-size: 15px"><strong>W14</strong><br /></span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>阿坝州九寨对外贸易有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >ABA PREFECTURE JIUZHAI FOREIGN TRADE CO., LTD INTRODUCTION OF MAIN
            VARIETIES</span
          >
        </p>
        <p>
          <span style="font-size: 15px"><strong>V13</strong></span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>安信纳米生物科技（珠海）有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >ANSON NANO-BIOTECHNOLOGY (ZHUHAI) CO., LTD.</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/c.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-7&nbsp; 长春人民药业集团有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >CHANGCHUN PEOPLE&nbsp; PHARMACEUTICAL GROUP CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U10&nbsp; 成都通灵中药饮片精选有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">CHENGDU TOONLYN HERBS CO. LTD.,</span>
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/d.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 东阿阿胶股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">DONG E EJIAO CORPORATION LIMITED.</span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 东平县瑞青玫瑰开发有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >DONGPING RUIQING ROSE DEVELOPMENT CO., LTD</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/g.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>Y24&nbsp; 广州医药集团有限公司&nbsp;</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >GUANGZHOU PHARMACEUTICAL HOLDINGS LIMITED</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U12&nbsp; 广东一方制药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >GUANGDONG YIFANG PHARMACEUTICAL CO.,LTD</span
          >
        </p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/h.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>W02&nbsp; 杭州五点零文化创意有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HANGZHOU WUDIANLING CULTURE AND CREATIVE CO.,LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W01&nbsp; 杭州欣园家具有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HANGZHOU XINYUAN FURNITURE CO.,LTD .</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>V01&nbsp; 杭州之名茶叶有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">HANGZHOU ZHIMING TEA CO., LTD</span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U04&nbsp; 合肥奥美康进出口贸易有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HEFEI AMEDCARE IMPORT AND EXPORT TRADE CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>X09&nbsp; 河南省保和堂医药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN BAOHETANG PHARMACEUTICAL CO.,LTD</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="font-size: 15px"
            ><strong>U07&nbsp; 河南超纳进出口贸易有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN CHAONA IMPORT AND EXPORT TRADE CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y06&nbsp; 河南大别山詹氏蜜蜂园有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y14&nbsp; 河南妙奇商贸有限公司</strong>&nbsp;</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN FANTASTIC INTERNATIONAL TRADING CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong
              >X10&nbsp; 河南郭生白中医养生有限责任公司&nbsp;</strong
            ></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN GUOSHENGBAI TRADITIONAL CHINESE MEDICINE HEALTH PRESERVATION
            CO LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y10&nbsp; 河南华康宏力医疗器械有限公司&nbsp;</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN HUAKANG HONGLI MEDICAL DEVICES CO.,LTD</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="font-size: 15px"
            ><strong>X12&nbsp; 河南省焦作市对外贸易公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN JIAOZUO FOREIGN TRADE CORPORATION</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y09&nbsp; 河南金鸿堂制药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN JINHONGTANG PHARMACEUTICAL CO. , LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>X06&nbsp; 河南省康华药业股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN KANGHUA PHARMACEUTICAL CO,LTD&nbsp;</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y02&nbsp; 河南泰丰生物科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN TAIFENG BIOTECHNOLOGY CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>X01&nbsp; 河南新永泰医药科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN XINYONGTAI MEDICAL TECHNOLOGY CO., LTD.</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y04&nbsp; 禹州市合同泰药业有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN YUZHOU CITY HETONGTAI PHARMACEUTICAL CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Z11&nbsp; 河南省芝元堂药业有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HENAN ZHIYUANTANG PHARMACEUTICAL CO., LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>X11&nbsp; 怀山堂生物科技股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HUAISHANTANG BIOTECHNOLOGY CO., LTD., LOCATED</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W05&nbsp; 湖北妍琪生物科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HUBEI YANQI BIOTECHNOLOGY CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W12&nbsp; 湖北一世缘蕲艾生物科技股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HUBEI YISHIYUAN CHINESE MUGWORT&nbsp; BIOTECHNOLOGY.CO.，LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-2&nbsp; 珲春华瑞参业生物工程股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >HUNCHUN HUARUI GINSENG BIOENGINEERING STOCK CO.,LTD.</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/j.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>U18&nbsp; 江苏七〇七天然制药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JIANGSU 707 NATURAL PHARMACEUTICAL CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U18&nbsp; 江苏康缘药业股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JIANGSU KANION PHARMACEUTICAL CO.,LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U18&nbsp; 江阴天江药业有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JIANGYIN TIANJIANG PHARMACEUTICAL CO.，LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-4&nbsp; 吉林敖东世航药业股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN AODONG SHIHANG PHARMACEUTICAL CO. , LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-8&nbsp; 吉林省生态实业股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN ECOLOGICAL SCIENCE &amp; TECHNOLOGY CO.,LTD</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="font-size: 15px"
            ><strong>U01&nbsp; 吉林蜂道馆健康产业有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN FENGDAOGUAN HEALTH INDUSTRY CO.，LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-3&nbsp; 吉林省柏农进出口有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN PROVINCE BAINONG IMPORT AND EXPORT CO. LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-9&nbsp; 吉林省蒲川生物医药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JINLIN PUCHUAN BIO-MEDICAL CO.,LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-10&nbsp; 吉林省长白山人参有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN PROVINCE CHANGBAI MOUNTAIN GINSENG CO., LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U24-6&nbsp; 吉林省双裕进出口有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >JILIN SHUANGYU IMPORT AND EXPORT CO. LTD</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/l.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>U18&nbsp; 雷允上药业集团有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >LEI YUN SHANG PHARMACEUTICAL GROUP CO., LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 临清清源正本生物医药科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >LINQING QINGYUAN BIOMEDICAL TECHNOLOGY CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Y05&nbsp; 林州陵阳郭氏正骨门诊部</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >LINZHOU LINGYANG GUO‘S ORTHOPEDIC CLINIC</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/n.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>W09&nbsp; 南京生命能科技开发有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANJING LIFENERGY R&amp;D CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U18&nbsp; 南京中医药大学</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANJING UNIVERSITY OF CHINESE MEDICINE</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W11&nbsp; 南京中科药业有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANJING ZHONGKE PHARMACEUTICAL CO.,LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Z09&nbsp; 南阳市东升艾绒厂</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">NANYANGSHI DONGSHENG AIRONGCHANG</span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Z03&nbsp; 南阳贤殊堂生物科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANYANG XIANSHUTANG BIOTECHNOLOGY CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Z05&nbsp; 南阳兴宛堂艾草制品有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANYANG XINGWANTANG WORMWOOD PRODUCTS CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>Z06&nbsp; 南阳一颗艾艾制品有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >NANYANG YIKEAI WORMWOOD&nbsp; PRODUCTS CO&nbsp; LTD</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/q.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong
              >V09&nbsp;
              杭州千甌雪茶葉有限公司/臨滄千甌雪茶葉有限公司&nbsp;&nbsp;</strong
            ></span
          >
        </p>
        <p><span style="font-size: 15px">QIANOUXUE TEA CO., LTD.</span></p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 青州尧王制药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >QINGZHOU YAOWANG PHARMACEUTICAL CO. LTD</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/r.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 瑞阳制药股份有限公司</strong></span
          >
        </p>
        <p><span style="font-size: 15px">REYOUNG</span></p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/s.webp" />
        </div>
        <p>
          <span style="font-size: 15px"
            ><strong>X24&nbsp; 山东省药学科学院</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG ACADEMY OF PHARMACEUTICAL SCIENCES</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 山东博奥克生物科技有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG BOAKE BIOTECHNOLOGY CO., LTD</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 山东福牌阿胶股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">SHANDONG FU PAI EJIAO CO., LTD</span>
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>X24&nbsp; 山东宏济堂制药集团股份有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG HONGJITANG PHARMACEUTICAL GROUP CO., LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 济宁市国医堂大药房有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG JINING GUOYITANGCHINESE MEDICINE TANG DRUGSTORE CO.,
            LTD</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="font-size: 15px"
            ><strong>W18&nbsp; 山东清荷工艺品有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG QINGHE HANDICRAFT CO.,LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >X24&nbsp; 山东山左控股集团有限责任公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG SHANZUO HOLDING GROUP CO., LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W18&nbsp; 山东泰尚黄精生物科技有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG TAISHANG HUANGJING BIOTECHNOLOGY CO. , LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >X24&nbsp; 山东同程尚品文化传媒有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG TONGCHENG SHANGPIN CULTURAL MEDIA CO.,LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W18&nbsp; 山东中医药大学</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG UNIVERSITY OF TRADITIONAL CHINESE MEDICINE</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"><br /></span
          ></strong>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W18&nbsp; 山东珍益药业有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG ZHENYI PHARMACEUTICAL CO. , LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W18&nbsp; 山东正基药业有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHANDONG ZHENGJI PHARMACEUTICAL CO., LTD.</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            ><strong>U02&nbsp; 山西振东医药有限公司</strong></span
          >
        </p>
        <p>
          <span style="font-size: 15px">SHANXI ZHENDONG MEDPHARM CO.,LTD.</span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >U06&nbsp; 石家庄以岭药业股份有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SHIJIAZHUANG YILING PHARMACEUTICAL CO., LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Z14&nbsp; 西施兰（南阳）药业股份有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >SISLAN(NANYANG) PHARMACEUTICAL COMPANY LIMITED&nbsp;</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/t.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Y11&nbsp; 天津药物研究院有限公司</span
            ></strong
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >TIANJIN INSTITUTE OF PHARMACEUTICAL RESEARCH CO., LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W13&nbsp; 通化圣昌食品有限公司</span
            ></strong
          ><span style="font-size: 15px">&nbsp;</span>
        </p>
        <p>
          <span style="font-size: 15px"
            >TONGHUA SHENGCHANG FOOD CO.,LTD.&nbsp;</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/v.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W10&nbsp; 珠海威尔凯化工有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >VITAL-CHEM ZHUHAI CO., LTD.&nbsp; &nbsp;</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/w.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Y01&nbsp; 温州市皓丰机械有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px">WENZHOU HAOFENG MACHINERY CO.,LTD</span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Z12&nbsp; 吴江市云龙医疗器械有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >WUJIANG CITY CLOUD &amp; DRAGON MEDICAL DEVICE CO., LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V11&nbsp; 吴江市佳辰针灸器械有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >WUJIANG JIACHEN ACUPUNCTURE DEVICES CO.,LTD.</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/x.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V14&nbsp; 咸阳步长贸易发展有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >XIANYANG BUCHANG TRADE DEVELOPMENT CO., LTD.</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/y.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >U24-5&nbsp; 延边宝利祥蜂业股份有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >YANBIAN BAOLIXIANG BEEKEEPING CO.,LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V02&nbsp; 义乌市坤林医疗器械有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >YIWU KYLIN MEDICAL EQUIPMENT CO., LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V12&nbsp; 云南中医药大学</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >YUNNAN UNIVERSITY OF CHINESE MEDICINE</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
        <div style="text-align: center; width: ">
          <van-image width="100%" src="/img/z.webp" />
        </div>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V06&nbsp; 浙江生生堂国药集团有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p><span style="font-size: 15px">ZHEJIANG SST GROUP</span></p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >V05&nbsp; 浙江叶同仁健康食品有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHEJIANG YETONGREN HEALTH FOOD CO., LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Z10&nbsp; 浙江中浙国际贸易中心有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHEJIANG ZHONGZHE INTERNATIONAL&nbsp;</span
          ><span style="font-size: 15px">TRAD</span
          ><span style="font-size: 15px">E CO.,LTD</span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >X04&nbsp; 浙江中浙国际贸易中心有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHEJIANG ZHONGZHE INTERNATIONAL&nbsp;</span
          ><span style="font-size: 15px">T</span
          ><span style="font-size: 15px">RADE CO.,LTD</span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Z02&nbsp; 浙江中浙国际贸易中心有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHEJIANG ZHONGZHE INTERNATIONAL&nbsp;</span
          ><span style="font-size: 15px">TRADE CO.,LTD</span>
        </p>
        <p>
          <span style="font-size: 15px"><br /></span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >X02&nbsp; 郑州市圆炁堂非遗健康养生有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHENGZHOU YUAN QI TANG INTANGIBLE CULTURAL HERITAGE HEALTH
            PRESERVATION CO. LTD</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Y13&nbsp; 郑州御品堂医疗器械有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHENGZHOU YUPINTANG MEDICAL INSTRUMENT CO.,LTD.</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >Y12&nbsp; 郑州瑞龙制药股份有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W24&nbsp;
              珠海市对外经济合作企业协会（广东省珠海市金湾国家外贸转型升级基地（生物医药）工作站）</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHUHAI ENTERPRISES ASSOCIATION FOR FOREIGN ECONOMIC
            COOPERATION</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >U03&nbsp; 珠海经济特区天然药物研究所有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHUHAI NATURAL PHARMACEUTICAL INSTITUTE</span
          >
        </p>
        <p>
          <strong
            ><span style="font-size: 15px"
              >W06&nbsp; 珠海萱嘉君行健康产业发展有限公司</span
            ></strong
          ><span style="font-size: 15px"></span>
        </p>
        <p>
          <span style="font-size: 15px"
            >ZHUHAI XUANJIA JUNXING HEALTH INDUSTRY DEVELOPMENT CO</span
          >
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mingdan",
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
</style>

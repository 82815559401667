<template>
  <div class="width720">
    <van-nav-bar
      :title="title"
      :left-text="leftTxt"
      left-arrow
      @click-left="back"
    />
    <div class="context" v-html="tuwen" />
    <div v-if="meeting_id" class="bbtn">
      <van-button type="primary" block @click="meetingbtn(meeting_id)">立即报名</van-button>
    </div>


    <van-action-sheet v-model="showBaoming" title="请选择人员">
      <van-checkbox-group v-model="checkbox">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in order_list"
            clickable
            :key="item.id"
            :title="item.name"
            @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item.id" ref="checkboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>

      <van-button type="primary" block @click="queding">确定</van-button>
    </van-action-sheet>
    <!-- <van-submit-bar button-text="立即报名" button-type="info" text-align="center" @submit="onSubmit" /> -->
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getSignature } from '@/api/user'
import { getOrderList,sendMeetingJoin } from '@/api/meeting'
import { getExpoInfo } from '@/api/expo'
export default {
  name: 'Tuwen',
  data() {
    return {
       title:'',
       tuwen: '',
       shareURL: '',
       ex_id: '',
       meeting_id: '',
       order_list: [],
       showBaoming: false,
       checkbox: [],
       leftTxt:"首页",
       shareDesc: "",
    };
  },
  created() {
    var _this = this
    var tw_id = this.$route.query.tuwen_id
    this.meeting_id = this.$route.query.meeting_id
    if(this.meeting_id){
      this.leftTxt = "返回"
    }

    this.shareURL =  location.protocol+'//' + location.host + this.$route.path + '?'
    this.shareURL += 'tuwen_id=' + tw_id
    this.shareURL += '&sub_user_id=' + localStorage.getItem('user_id')
    if (this.partner_id) {
      this.shareURL += '&partner_id=' + this.partner_id
    }

    let ua = navigator.userAgent.toLowerCase();  //判断是否是微信端
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.getWxShare()
    }

    _this.$axios.get('/api/admin/tuwen/getTuwen', {params:{tuwen_id:tw_id}})
    .then((res) => {
        console.log(res.data)
        _this.title = res.data.data.tuwen_title
        _this.tuwen = res.data.data.tuwen_content
        document.title = res.data.data.tuwen_title
        _this.ex_id = res.data.data.ex_id
        getExpoInfo(_this.ex_id).then((res)=>{
          _this.shareDesc = res.ex_name
        })
    });
    
  },
  methods: {
    back() {
      
      if(this.meeting_id) {
        this.$router.push({
          name:"mtList",
          query: {}
        })
        
      } else {
        this.$router.push("/wx/home?ex_id="+this.ex_id)
      }
    },
    meetingbtn(meeting_id){
      var user_id = localStorage.getItem("user_id")
      var data = { "meeting_id": meeting_id, "user_id":user_id, "ex_id":this.ex_id }
      var that = this
      getOrderList(data).then(res=>{
        if(res.code == 10000){
          that.order_list = res.data
          console.log(data)
          if (res.data.length == 0){
            
            this.$router.push({
              name:"AttendMeeting",
              query: data
            })
          } else {
            this.showBaoming = true;
          }
        }
      })
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      console.log(this.checkbox)

    },
    queding() {
      var that = this
      var user_id = localStorage.getItem("user_id")
      var data = { 
        "meeting_id": this.meeting_id,
        "user_id": user_id,
        "ex_id":this.ex_id,
        "checkbox": this.checkbox}
      sendMeetingJoin(data).then(res=>{
        if(res.code == 10000) {
          that.showBaoming = false;
        }
      })
    },
    getWxShare(){
      let url = window.location.href
      var that = this
      getSignature(url).then(res=>{
          if(res.code==10000){
            let wxInfo=JSON.parse(res.data)
            console.log(wxInfo)
            wx.config({
                debug: false, // 是否开启调试模式
                appId: wxInfo.appid, //appid
                timestamp: Number(wxInfo.timestamp), // 时间戳
                nonceStr: wxInfo.noncestr, // 随机字符串
                signature: wxInfo.signature, // 签名
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData'
                ] // 需要使用的JS接口列表
            })
            wx.ready(()=>{  
                wx.updateAppMessageShareData({ 
                  title: this.title,     // 分享标题
                  desc: this.shareDesc,     // 分享描述
                  link: that.shareURL,     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'http://newdrug.cphi-china.cn/img/share.jpeg',     // 分享图标
                  success: function () {
                      console.log("成功")
                  },
                  cancel:function(){
                      console.log("取消")
                  }
                })
                wx.updateTimelineShareData({ 
                  title: this.title,     // 分享标题
                  desc: this.shareDesc,     // 分享描述
                  link: that.shareURL,     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'http://newdrug.cphi-china.cn/img/share.jpeg',     // 分享图标
                  success: function () {
                      console.log("成功")
                  },
                  cancel:function(){
                      console.log("取消")
                  }
                })
            })
            wx.error(function(res){
                console.log(res.errMsg)
            })
          }
      }).catch()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}

.width720 {
  margin: auto;
}
.bbtn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}

</style>

<template>
  <div>
    <van-nav-bar
      title="同期活动"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <van-image
        width="100%"
        src="/img/01.jpg" @click="onHuiyi('1')"
        />
      <van-image
        width="100%"
        src="/img/02.jpg" @click="onHuiyi('2')"
        />
      <van-image
        width="100%"
        src="/img/03.jpg" @click="onHuiyi('3')"
        />
      <van-image
        width="100%"
        src="/img/04.jpg"  @click="onHuiyi('4')"
        />
    </div>
  </div>
</template>

<script>
export default {
  name: 'huodong',
  data() {
    return {
       
    };
  },
  methods: {
    back() {
      this.$router.push({
              name: "Home",
              params:{}
            })
    },
    onHuiyi(id) {
      switch(id) {
        case "1":
            this.$router.push({
              name: "h1",
              params:{}
            })
            break;
        case "2":
            this.$router.push({
              name: "h2",
              params:{}
            })
            break;
        case "3":
            this.$router.push({
              name: "h3",
              params:{}
            })
            break;
        case "4":
            this.$router.push({
              name: "h4",
              params:{}
            })
            break;
        default:
            console.log("无")
      } 
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}

</style>
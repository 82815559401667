<template>
  <div>
    <van-nav-bar
      title="赞助申请"
      left-text="首页"
      left-arrow
      @click-left="onClickLeft"
      :right-text="rightText"
      @click-right="showShare = true"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>资料确认</van-step>
      <van-step>申请完成</van-step>
    </van-steps>
    <van-form @submit="onSubmit">
      <van-cell-group name="baseinfo" title="填写资料">

        <van-field v-model="name" name="name"
          required 
          label="姓名" 
          placeholder="请输入姓名"  
          :rules="[{ required: true, message: '请输入姓名' }]"
        />

        <van-field v-model="company" name="company"
          required 
          label="公司/机构" 
          placeholder="请输入公司/机构名称全称"  
          :rules="[{ required: true, message: '请输入公司/机构名称全称' }]"
        />

        <van-field v-model="zhiwei"  name="zhiwei"
          required
          label="职位" 
          placeholder="请输入职位"  
          :rules="[{ required: true, message: '请输入职位' }]"
        />

        <van-field v-model="shouji" name="shouji"
        required
          type="tel" 
          label="手机号" 
          placeholder="请输入手机号"  
          :rules="[{ required: true, pattern:/[1][3-9]\d{9}$|([6|9])\d{7}$|[0][9]\d{8}$|6\d{5}$/, message: '请正确输入手机号码' }]"
        />

        <van-field v-model="email"  name="email"
          required
          label="邮箱" 
          placeholder="请输入邮箱"  
          :rules="[{ required: true, pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入邮箱'}]"
        />

      </van-cell-group>

      <van-cell-group title="合作项目">
        <van-field name="hezuoxiangmu" label="合作项目"
          required
          :rules="[{ required: true, message: '请选择'}]"
          >
          <template #input>
            <van-checkbox-group v-model="hezuoxiangmu" >
              <van-checkbox name="0" shape="square">赞助（广告、印刷品、会议物资、礼品）</van-checkbox>
              <van-checkbox name="1" shape="square">演讲及圆桌讨论</van-checkbox>
              <van-checkbox name="2" shape="square">展位</van-checkbox>
              <van-checkbox name="3" shape="square">冠名</van-checkbox>
              <van-checkbox name="4" shape="square">其他</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
      </van-cell-group>

      
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">下一步</van-button>
      </div>
    </van-form>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
  </div>
  
</template>

<script>
import { Toast } from 'vant';
import wx from 'weixin-js-sdk'
import { getSignature } from '@/api/user'

export default {
  name: 'AttendMeeting',
  data() {
    return {
      ex_id: '',
      name: '',
      company: '',
      department: '',
      zhiwei: '',
      diqu: '',
      areaCode: '',
      dizhi: '',
      dianhua: '',
      shouji: '',
      email: '',
      active: 0,
      hezuoxiangmu:[],
      brand: '',
      ip: '',
      city: '',
      partner_id: '',
      sub_user_id: '',
      showShare: false,
      options: [
        { name: '复制链接', icon: 'link' }
      ],
      shareURL: '',
      rightText: ''
    };
  },
  created() {
    window.scrollTo(0,0);
    this.isFirstEnter=true;
    this.ex_id=this.$route.query.ex_id
    this.partner_id=this.$route.query.partner_id
    this.sub_user_id=this.$route.query.sub_user_id

    //ip
    // this.$axios.post('/apil/cityjson?ie=utf-8',{}).then(res=>{
    //   var cityjson = res.data
    //   var start = cityjson.lastIndexOf('{')
    //   var end = cityjson.lastIndexOf('}') + 1
    //   var jsondata = JSON.parse(cityjson.substring(start,end))
    //   this.ip = jsondata.cip
    //   this.city = jsondata.cname
    // })
    this.ip = sessionStorage.getItem('ip')
    this.city = sessionStorage.getItem('cname')

    // 引用mobile-detect文件
    var MobileDetect = require('mobile-detect');
    var md = new MobileDetect(navigator.userAgent);
    // 品牌
    this.brand = md.mobile();
    if (this.brand == null) {
      this.brand = "电脑PC"
      this.rightText = "分享"
    }

    
    //调取缓存内容
    this.chenghu=localStorage.getItem('hchenghu');
    this.name=localStorage.getItem('hname');
    this.company=localStorage.getItem('hcompany');
    this.zhiwei=localStorage.getItem('hzhiwei');
    this.shouji=localStorage.getItem('hshouji');
    this.email=localStorage.getItem('hemail');
    if(localStorage.getItem('hhezuoxiangmu')){
      this.hezuoxiangmu=localStorage.getItem('hhezuoxiangmu').split(',');
    }
    this.shareURL =  location.protocol+'//' + location.host + this.$route.path + '?'
    this.shareURL += 'sub_user_id=' + localStorage.getItem('user_id')
    if (this.partner_id) {
      this.shareURL += '&partner_id=' + this.partner_id
    }

    let ua = navigator.userAgent.toLowerCase();  //判断是否是微信端
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.getWxShare()
    }
  },
  //路由跳转回来的时候,
  beforeRouteEnter(to, from, next) {
  //从以下页面进入时候不刷新
      if(from.name=='AttendConfirm' || from.name=='AttendConfirm'){   
          to.meta.isBack=true;
      }
      // this.chenghu=localStorage.getItem('chenghu');
      next();
  },
  //路由跳转的时候
  beforeRouteLeave(to,from,next){
    
    next();
  },
    
  activated() {
      if(!this.$route.meta.isBack || this.isFirstEnter){
          //  刷新时候执行
          // layer.load(2);
          //this.initData()    执行方法
      }
      this.$route.meta.isBack=false
      this.isFirstEnter=false;
  }, 
  methods: {
    onClickLeft() {
      this.$router.push("/wx/home?ex_id="+"1")
    },
    onClickRight() {
      Toast('按钮');
    },
    onChenghuConfirm(value) {
      this.chenghu = value;
      this.showChenghuPicker = false;
    },
    onDiquConfirm(value) {
      this.diqu = "";
      this.dizhi = "";
      for(let item of value){
          if (this.diqu != "") {
            this.diqu += "/"
          }

          this.diqu += item.name
          this.dizhi += item.name
      }
      
      this.areaCode = value[2]["code"];
      this.showDiquPicker = false;
    },
    onSubmit(values) {
      let meeting_info = { "meeting_id": this.meeting_id, "meeting_name": this.meeting_name}
      let subInfo = { 
        "brand" : this.brand,
        "ip": this.ip,
        "city": this.city,
        "partner_id": this.partner_id,
        "sub_user_id": this.sub_user_id,
      }
      let data = { ...meeting_info, ...values, ...subInfo };
      localStorage.setItem('hname', this.name);
      localStorage.setItem('hcompany', this.company);
      localStorage.setItem('hzhiwei', this.zhiwei);
      localStorage.setItem('hshouji', this.shouji);
      localStorage.setItem('hemail', this.email);
      localStorage.setItem('hhezuoxiangmu', this.hezuoxiangmu);

      this.$router.push({
          name:"hezuoConfirm",//这个path就是你在router/index.js里边配置的路径
          params:data
      })
    },
    onSelect() {
      let that = this;
      this.$copyText(that.shareURL).then(
        function () {
          Toast("复制成功");
          that.showShare = false;
        },
        function () {
          Toast("复制失败");
          that.showShare = false;
        }
      );
    },
    getWxShare(){
        let url = window.location.href //http://newdrug.cphi-china.cn/wx/hezuo?sub_user_id=null
        var that = this
        getSignature(url).then(res=>{
            if(res.code==10000){
              let wxInfo=JSON.parse(res.data)
              console.log(wxInfo)
              wx.config({
                  debug: false, // 是否开启调试模式
                  appId: wxInfo.appid, //appid
                  timestamp: Number(wxInfo.timestamp), // 时间戳
                  nonceStr: wxInfo.noncestr, // 随机字符串
                  signature: wxInfo.signature, // 签名
                  jsApiList: [
                      'updateAppMessageShareData',
                      'updateTimelineShareData'
                  ] // 需要使用的JS接口列表
              })
              wx.ready(()=>{  
                  wx.updateAppMessageShareData({ 
                      title: '赞助申请',     // 分享标题
                      desc: '2022新药开发与合作高峰论坛',     // 分享描述
                      link: that.shareURL,     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                      imgUrl: 'http://newdrug.cphi-china.cn/img/share.jpeg',     // 分享图标
                      success: function () {
                          console.log("成功")
                      },
                      cancel:function(){
                          console.log("取消")
                      }
                  })
                  wx.updateTimelineShareData({ 
                      title: '赞助申请',     // 分享标题
                      desc: '2022新药开发与合作高峰论坛',     // 分享描述
                      link: that.shareURL,     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                      imgUrl: 'http://newdrug.cphi-china.cn/img/share.jpeg',     // 分享图标
                      success: function () {
                          console.log("成功")
                      },
                      cancel:function(){
                          console.log("取消")
                      }
                  })
              })
              wx.error(function(res){
                  console.log(res.errMsg)
              })
            }
        }).catch()
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

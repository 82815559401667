<template>
  <div>
    <van-nav-bar
      title="报名参加"
      left-text="修改"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>同行人报名</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-cell-group name="baseinfo" title="填写资料">

      <van-field v-model="company" name="company"
        readonly
        label="公司/机构" 
      />
      <van-field v-model="name" name="name"
        readonly
        label="姓名" 
      />

      <van-field v-model="zhiwei"  name="zhiwei"
        readonly
        label="职位" 
      />

      <van-field v-model="shouji" name="shouji"
        readonly
        type="tel" 
        label="手机号" 
      />

      <van-field v-model="email"  name="email"
        readonly
        label="邮箱" 
      />

      <van-field name="piao" v-model="piao" 
        label="票种类" 
        readonly
      />
    </van-cell-group>
    <van-cell-group name="baseinfo" title="公司/机构业务">
      <van-collapse v-model="coll">
        <van-collapse-item title="业务类别" name="1">
          <li v-for="item in yewuleibie" :key="item">
            {{ yewuleibieTxt[item] }}
          </li>
        </van-collapse-item>
        <van-collapse-item title="感兴趣领域" name="2">
          <li v-for="item in xingqu" :key="item">
            {{ xingquTxt[item] }}
          </li>
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    <van-cell-group name="baseinfo" title="同行人员">
      <van-cell v-for="(item, index) in tongxing" :key="index" :title="item.name" :value="item.piao" :label="item.shouji" is-link @click="editTongxing(index)" />
    </van-cell-group>
    <van-contact-card type="add" add-text="添加同行人员" @click="onAdd" />
    <div>
      <van-row>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="default" @click="onEdit">修改</van-button>
          </div>
        </van-col>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="primary" :disabled="next_btn_disabled" @click="onNext">下一步</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
    <van-action-sheet v-model="addTongxingShow" title="添加">
      <van-form @submit="addTongxing">
        <van-field v-model="tongxingpepo.name" name="name"
          required 
          label="姓名" 
          placeholder="请输入姓名"  
          :rules="[{ required: true, message: '请输入姓名' }]"
        />

        <van-field v-model="tongxingpepo.zhiwei"  name="zhiwei"
          required
          label="职位" 
          placeholder="请输入职位"  
          :rules="[{ required: true, message: '请输入职位' }]"
        />

        <van-field v-model="tongxingpepo.shouji" name="ckShouji"
        required
          type="tel" 
          label="手机号" 
          placeholder="请输入手机号"  
          :rules="[{ required: true, pattern:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-9,5-9]))[0-9]{8}$/, message: '请正确输入手机号码' },
          {required: true, validator: shoujiCheck, message: '一个手机号只能购买一张票' },
          { validator: ckShouji, message: '该手机号已经报名注册过，请选择其他手机号' }]"
        />

        <van-field v-model="tongxingpepo.email"  name="email"
          required
          label="邮箱" 
          placeholder="请输入邮箱"  
          :rules="[{ required: true, pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入邮箱'},{
              validator: ckEmail,
              message: '该邮箱已经报名注册过，请选择其他邮箱报名。',
            },
          ]"
        />

        <van-field name="piao" label="票种类" required  placeholder="请选择票种类" :rules="[{ required: true, message: '请选择票种类' }]" >
          <template #input>
            <van-radio-group v-model="tongxingpepo.piao" direction="horizontal">
              <van-radio name="参会票" checked>参会票</van-radio>
              <!-- <van-radio name="标准票">标准票</van-radio> -->
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">添 加</van-button>
        </div>
      </van-form>
    </van-action-sheet>

    <van-action-sheet v-model="editTongxingShow" title="编辑">
      <van-form @submit="updateTongxing">
        <van-field v-model="edittongxingpepo.name" name="name"
          required 
          label="姓名" 
          placeholder="请输入姓名"  
          :rules="[{ required: true, message: '请输入姓名' }]"
        />

        <van-field v-model="edittongxingpepo.zhiwei"  name="zhiwei"
          required
          label="职位" 
          placeholder="请输入职位"  
          :rules="[{ required: true, message: '请输入职位' }]"
        />

        <van-field v-model="edittongxingpepo.shouji" name="ckShouji"
          required
          type="tel" 
          label="手机号" 
          placeholder="请输入手机号"  
          :rules="[{ required: true, pattern:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-9,5-9]))[0-9]{8}$/, message: '请正确输入手机号码' },
          { equired: true, validator: shoujiCheckEdit, message: '一个手机号只能购买一张票'},
          { validator: ckShouji, message: '该手机号已经报名注册过，请选择其他手机号' }]"
        />
        <van-field v-model="edittongxingpepo.email"  name="email"
          required
          label="邮箱" 
          placeholder="请输入邮箱"  
          :rules="[{ required: true, pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入邮箱'},{
              validator: ckEmail,
              message: '该邮箱已经报名注册过，请选择其他邮箱报名。',
            }
          ]"
        />

        <van-field name="piao" label="票种类" required  placeholder="请选择票种类" :rules="[{ required: true, message: '请选择票种类' }]" >
          <template #input>
            <van-radio-group v-model="edittongxingpepo.piao" direction="horizontal">
              <van-radio name="参会票">参会票</van-radio>
              <!-- <van-radio name="标准票">标准票</van-radio> -->
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">保存</van-button>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="info" @click="delTongxing(index)">删除</van-button>
        </div>
      </van-form>
    </van-action-sheet>
  </div>
</template>

<script>
// import { Dialog } from 'vant';
import { addOrder, checkShouji, checkEmail } from '@/api/zhuce'
import {nanoid} from  'nanoid'

export default {
  name: 'zhuceAdd',
  data() {
    return {
      meeting_id: '',
      meeting_name: '',
      piao: '',
      piaos: ['参会票'],
      showChenghuPicker: false,
      name: '',
      company: '',
      zhiwei: '',
      shouji: '',
      email: '',
      active: 1,
      coll: ['1','2'],
      xingqu:[],
      yewuleibie:[],
      yewuleibieTxt: ['biotech','Pharma','院校/科研机构/医疗机构','CRO/CMO/CDMO','原辅料/试剂/耗材/仪器/设备/冷链/系统','政府部门/商协会/国际组织','数据/咨询/律所/投融资机构','媒体','社会观众及其他'],
      xingquTxt: ['小分子药物','抗体药物','ADC药物','细胞治疗','基因治疗','新型疫苗','mRNA'],
      tongxing: [],
      addTongxingShow: false,
      tongxingpepo: {
        name:'',
        shouji:'',
        piao:'参会票',
        email:'',
        zhiwei:''},
      editTongxingShow: false,
      editIndex: 0,
      edittongxingpepo: {},
      brand: '',
      ip: '',
      city: '',
      partner_id: '',
      sub_user_id: '',
      next_btn_disabled: false
    };
  },
  created() {
    window.scrollTo(0,0);

    //ip
    // this.$axios.post('/apil/cityjson?ie=utf-8',{}).then(res=>{
    //   var cityjson = res.data
    //   var start = cityjson.lastIndexOf('{')
    //   var end = cityjson.lastIndexOf('}') + 1
    //   var jsondata = JSON.parse(cityjson.substring(start,end))
    //   this.ip = jsondata.cip
    //   this.city = jsondata.cname
    // })
    this.ip = sessionStorage.getItem('ip')
    this.city = sessionStorage.getItem('cname')

    // 引用mobile-detect文件
    var MobileDetect = require('mobile-detect');
    var md = new MobileDetect(navigator.userAgent);
    // 品牌
    this.brand = md.mobile();
    if (this.brand == null) {
      this.brand = "电脑PC"
    }

    console.log(this.$route.params)
    this.piao = this.$route.params.piao;
    this.name = this.$route.params.name;
    this.company = this.$route.params.company;
    this.zhiwei = this.$route.params.zhiwei;
    this.dianhua = this.$route.params.dianhua;
    this.shouji = this.$route.params.shouji;
    this.email = this.$route.params.email;
    this.xingqu = this.$route.params.xingquGroup;
    this.yewuleibie = this.$route.params.yewuleibieGroup;
    this.meeting_id=this.$route.params.meeting_id
    this.meeting_name=this.$route.params.meeting_name
    this.hexiao_type=this.$route.params.hexiao_type

    this.partner_id=this.$route.params.partner_id
    this.sub_user_id=this.$route.params.sub_user_id
    if(localStorage.getItem('tongxing')){
      this.tongxing = JSON.parse(localStorage.getItem('tongxing'))
    }
    
  },
  methods: {
    shoujiCheck(value){
      if (this.shouji == value) {
        return false
      }
      let cut = 0
      
      this.tongxing.forEach( function(item){
        if (item.shouji == value) {
          cut += 1
        }
      })

      if (cut > 0) {
        return false
      }

      return true
    },
    shoujiCheckEdit(value){
      if (this.shouji == value) {
        return false
      }
      let cut = 0
      this.tongxing.forEach( function(item){
        if (item.shouji == value) {
          cut += 1
        }
      })

      if (cut > 1) {
        return false
      }

      return true
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onNext() {
      this.next_btn_disabled = true
      var order_money = 0
      if (this.piao == "标准票") {
        order_money += 350
      }
      this.tongxing.forEach( function(item){
            if (item.piao == "标准票") {
              order_money += 350
        }
        } )
      var order_id = nanoid()
      var data = {'order_id': order_id,
                  'user_id':localStorage.getItem('user_id'),
                  'openid':localStorage.getItem('openid'),
                  'order_phone':localStorage.getItem('phone'),
                  'company': this.company,
                  'name': this.name,
                  'zhiwei': this.zhiwei,
                  'shouji': this.shouji,
                  'email': this.email,
                  'piao': this.piao,
                  'yewuleibie': this.yewuleibie,
                  'xingqu': this.xingqu,
                  'tongxing': this.tongxing,
                  'order_money': order_money,
                  'order_state': 0,
                  'order_pay_state': 0,
                  'city': this.city,
                  'ip': this.ip,
                  'brand': this.brand,
                  'partner_id': this.partner_id,
                  'sub_user_id': this.sub_user_id
                  }
      localStorage.setItem("tongxing",JSON.stringify(this.tongxing))
      //发送订单信息
      if ( this.tongxing && this.tongxing.length == 0) {
        addOrder(data).then((res) => {
          if (res.code == '10000') {
            
            localStorage.removeItem("piao");
            localStorage.removeItem("name");
            localStorage.removeItem("company");
            localStorage.removeItem("zhiwei");
            localStorage.removeItem("shouji");
            localStorage.removeItem("email");
            localStorage.removeItem("xingqu");
            localStorage.removeItem("yewuleibie");
            localStorage.removeItem("tongxing");
            this.next_btn_disabled = false;
            this.$router.push({
              name: "zhuceSuccess",
              query:{ 'order_id': order_id
              }
            })
          } 
        })
      } else if (this.tongxing.length > 0) {
        this.next_btn_disabled = false;
        this.$router.push({
              name: "zhuceTongxing",
              params: data
            })
      }
      
    },
    onEdit() {
      this.$router.go(-1);
    },
    onAdd() {
      this.addTongxingShow = true
      this.tongxingpepo = {
        name:'',
        shouji:'',
        piao:'参会票',
        email:'',
        zhiwei:''}
    },
    addTongxing() {
      this.tongxing.push(this.tongxingpepo)
      localStorage.setItem("tongxing",JSON.stringify(this.tongxing))
      this.addTongxingShow = false
    },
    editTongxing(index) {
      this.editIndex = index
      this.edittongxingpepo = this.tongxing[index]
      localStorage.setItem("tongxing",JSON.stringify(this.tongxing))
      this.editTongxingShow = true
    },
    updateTongxing() {
      localStorage.setItem("tongxing",JSON.stringify(this.tongxing))
      this.editTongxingShow = false
    },
    delTongxing(index) {
      this.tongxing.splice(index, 1)
      localStorage.setItem("tongxing",JSON.stringify(this.tongxing))
      this.editTongxingShow = false
    },
    ckShouji(val) {
      var data = {'shouji': val}
      return new Promise((resolve) => {
        resolve(
          checkShouji( data ).then(res=>{
            
            if (res.data.length > 0) {
              return false
            } else {
              return true
            }
          })
        );
      });
    },
    ckEmail(val) {
      var data = {'email': val}
      return new Promise((resolve) => {
        resolve(
          checkEmail( data ).then(res=>{
            if (res.data.length > 0) {
              return false
            } else {
              return true
            }
          })
        );
      });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
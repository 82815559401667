import Vue from 'vue';
import App from './App';
import router from './router.js';

import { Button } from "vant";
import { NavBar } from "vant";
import { Toast } from "vant";
import { Field } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Popup } from 'vant';
import { Picker } from 'vant';
import { Area } from 'vant';
import { Icon } from 'vant';
import { Form } from 'vant';
import { Step, Steps } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Col, Row } from 'vant';
import { Tag } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Empty } from 'vant';
import { NumberKeyboard } from 'vant';
import { PasswordInput } from 'vant';
import { Grid, GridItem } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Image as VanImage } from 'vant';
import { CountDown } from 'vant';
import { Card } from 'vant';
import { ContactCard } from 'vant';
import { ActionSheet } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { SubmitBar } from 'vant';
import { ShareSheet } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Switch } from 'vant';

import VueClipboard from 'vue-clipboard2'
import pvuv from '@/utils/PVUV'

import axios from 'axios'
Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api';
Vue.prototype.$pvuv = pvuv;

Vue.use(Button);
Vue.use(NavBar);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Area);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tag);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Empty);
Vue.use(NumberKeyboard);
Vue.use(PasswordInput);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(CountDown);
Vue.use(Card);
Vue.use(ContactCard);
Vue.use(ActionSheet);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(SubmitBar);
Vue.use(ShareSheet);
Vue.use(VueClipboard)
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Switch);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined?'':to.meta.title
  if (to.meta.requireAuth) {
    let phone = localStorage.getItem('phone');
    console.log(to.query)
    if (!phone) { 
      if ( to.query.id == 10 || to.query.id == 11 || to.query.id == 12) {
        console.log(to.query.id)
      } else {
        next({
          path: '/wx/login',
          query: {
            redirect: to.fullPath
          } 
        })
      }
    }
  }
  next();
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

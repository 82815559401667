<template>
  <div>
    <div style="text-align: center;">
        <div style="margin-top: 2rem">
            <van-icon name="checked" color="#07c160" size="6rem"/>
        </div>
        <p class="className" style="font-size: 20px;">签到成功</p>
        <p>请把屏幕展示给工作人员入场</p>
        <div style="margin-top: 4rem">
            <van-button hairline round type="primary" size="normal" url="/wx/home">查看其他会议详情</van-button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttendSuccess',
  data() {
    return {
      active: 2
    };
  },
  methods: {
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ainime{ 
    -webkit-animation: twinkling 1s infinite ease-in-out 
} 
.animated{ 
    -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both 
} 
@-webkit-keyframes twinkling{ 
    0%{ 
        opacity: 0.5; 
    } 
    100%{ 
        opacity: 1; 
    } 
} 
@keyframes twinkling{ 
    0%{ 
        opacity: 0.5; 
    } 
    100%{ 
        opacity: 1; 
    }   
}
</style>

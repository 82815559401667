<template>
  <div>
    <van-nav-bar
      title="报名参加"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>同行人报名</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <div v-if="order_info.order_state == 0">
      <div><p class="ssInfo">我们会尽快完成审核并给予通知</p></div>
      <div><van-image
        width="100%"
        src="/img/choujiang.jpeg"
        @click="onChoujiang"
      /></div>
    </div>
    <div  v-else-if="order_info.order_state == 1">
      <div><p class="ssInfo">审核通过请留意最新消息推送</p></div>
    </div>
    <van-cell-group name="baseinfo" title="购票信息">
      <van-collapse v-model="activeNames">
        <van-collapse-item name="1">
          <template #title>
            <div>{{ order_info.name }} <van-tag plain type="success">{{order_info.piao}}</van-tag></div>
          </template>
          公司：{{order_info.company}} <br> 
          职位：{{order_info.zhiwei}} <br>  
          手机：{{order_info.shouji}}<br>  
          邮箱：{{order_info.email}}<br> 
        </van-collapse-item>
        <van-collapse-item  v-for="(item, index) in JSON.parse(order_info.tongxing)" :key="index"  :name="index">
            <template #title>
              <div>{{ item.name }} <van-tag plain type="success">{{item.piao}}</van-tag></div>
            </template>
            公司：{{order_info.company}} <br> 
            职位：{{item.zhiwei}} <br>  
            手机：{{item.shouji}}<br>   
            邮箱：{{item.email}}<br> 
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    <div v-if="order_info.order_money !== 0">
      <van-cell-group name="baseinfo" title="付款信息">
        <van-card
          num="2"
          price="350"
          desc="描述信息"
          title="标准票"
          thumb=""
        />
      </van-cell-group>
    </div>
    <div v-else>
      
      <div class="homeBtn">
        <van-button icon="wap-home-o" type="primary" url="/wx/home?ex_id=1">首页</van-button>
      </div>
    </div>
    
    <div v-if="order_info.order_state == 0">
        <div v-if="order_info.order_money == 0">
           
        </div>
        <div v-else>
          <van-submit-bar :price="order_info.order_money * 100" button-text="支付" @submit="onPay" />
        </div>
    </div>
    <div v-else-if="order_info.order_state == 1">
    </div>
    <div v-else>
    </div>
    <van-action-sheet v-model="showPaysheet" cancel-text="取消"
  close-on-click-action :actions="actions" @select="onSelect" />


  </div>
</template>

<script>
import { getOrderInfo } from '@/api/zhuce'
import { Dialog } from 'vant';

export default {
  name: 'zhuceSuccess',
  data() {
    return {
      active: 2,
      order_id: '',
      order_info: {},
      company:'',
      activeNames:  ['1'],
      tongxing:[],
      showPaysheet: false,
      actions: [{ name: '汇款' }, { name: '微信支付' }],
    };
  },
  created() {
    window.scrollTo(0,0);
    this.order_id = this.$route.query.order_id
    console.log(this.order_id)
    getOrderInfo(this.order_id).then((res) => {
      this.order_info = res.data
      console.log(this.order_info)
    })
  },
  methods: {
    sign() {
    //   window.open("/page/Sign?meeting_id="+this.meeting_id)
    },
    onPay() {
      this.showPaysheet = true
    },
    onSelect(item){
      this.showPaysheet = false;
      console.log(item.name);
      if(item.name == "汇款") {
        Dialog.confirm({
        title: '汇款',
        message: '请您于3个工作日内完成对公汇款，我们会及时对您的汇款进行核对，审核通过后，我们会主动与您联系签订相关合同。将会通过乐展服务号、短信、邮件的方式提醒您报名进度，敬请留意，如有疑问，请与组委会毛女士021-33392181取得联系。\n汇款信息：XXXXXXXXXXXXXXX\n请务必在备注中写明选择标准票人员姓名。',
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
      }
      
    },
    onHuikuan(){

    },
    onChoujiang(){
      window.open("https://18491152-69.hd.ysfaisco.cn/18491152/PptZuQq_f1ZKNaq8Rgz9rA/load-18491152-69.html?style=115&_source=1");
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-image .van-empty__image {
    width: 90px;
    height: 90px;
}
.pay_btn {
  margin: 32px;
}

.homeBtn {
  width: 100px;
  margin: 60px auto;
}
.ssInfo {
  text-align: center;
}
</style>

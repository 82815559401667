<template>
  <div>
  <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item v-for="(banner, index) in banners" :key="index">
      <img width="100%" v-lazy="banner.banner_img" />
    </van-swipe-item>

  </van-swipe>
  <div class="grid">
    <van-grid :column-num="3" :gutter="10" clickable>
      <van-grid-item v-for="(gongge, index) in gongges" :key="index" :icon="gongge.gg_img" :text="gongge.gg_name" :url="gongge.gg_url"  />
    </van-grid >
  </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      banners: [],
      gongges: [],
      ex_id: '',
      sc_id: '',
      so_id: '',
    }
  },
  created() {
    this.ex_id = this.$route.query.ex_id
    this.sc_id = this.$route.query.sc_id
    this.so_id = this.$route.query.so_id
    if(!this.ex_id) {
      this.ex_id = 0
    }
    this.getExpo(this.ex_id)
    this.getBanner(this.ex_id)
    this.getGongge(this.ex_id)
    var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(navigator.userAgent);
    console.log(md.mobile())
  },
  methods: {
    getExpo(ex_id) {
      var _this = this
      _this.$axios.get('/api/admin/weizhan/getExpoInfo', {params:{ex_id: ex_id}})
      .then((res) => {
        console.log(res.data.data)
        document.title = res.data.data.data.ex_name
      });
    },
    getBanner(ex_id) {
      var _this = this
      _this.$axios.get('/api/admin/weizhan/getBanner', {params:{type:0,ex_id: ex_id}})
      .then((res) => {
        _this.banners = res.data.data
      });
    },
    getGongge(ex_id) {
      var _this = this

      _this.$axios.get('/api/admin/weizhan/getGongge', {params:{type:0, ex_id: ex_id}})
      .then((res) => {
        _this.gongges = res.data.data
        console.log(_this.gongges)
      });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.swipe {
  width: 100%;
  height: 210px;
}
.grid {
  margin-top: 10px;
}
</style>
<template>
  <div>
    <van-nav-bar
      title="报名参加"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>资料确认</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <div style="text-align: center;">
        <div style="margin-top: 2rem">
            <van-icon name="checked" color="#07c160" size="6rem"/>
        </div>
        <p style="font-size: 20px;">报名成功</p>
        <p>请关注公众号，接收会议最新消息</p>
        <!-- <div v-if="hexiao_type === 0 ">
          <div style="margin: 4rem">
            <van-button block hairline round type="primary" size="normal" url="/wx/home">查看其他会议详情</van-button>
          </div>
        </div>
        <div v-else-if="hexiao_type === 1">
          <div style="margin: 4rem">
            <van-button plain block hairline round type="default" size="normal" url="/wx/home">查看其他会议详情</van-button>
          </div>
        </div>
        <div v-else>
          <div style="margin: 4rem">
            <van-button block hairline round type="primary" size="normal" url="/wx/home">查看其他会议详情</van-button>
          </div>
        </div>   -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttendSuccess',
  data() {
    return {
      active: 2,
      hexiao_type: 0, //0不核销，1立即核销
      meeting_id:'',
    };
  },
  created() {
    window.scrollTo(0,0);
    this.hexiao_type=this.$route.params.hexiao_type,
    this.meeting_id=this.$route.params.meeting_id
  },
  methods: {
    sign() {
      window.open("/page/Sign?meeting_id="+this.meeting_id)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-image .van-empty__image {
    width: 90px;
    height: 90px;
}
</style>

<template>
  <div>
    <van-nav-bar
      title="观展须知"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <span
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            font-size: 20px;
          "
          ><strong
            style="
              box-sizing: border-box;
              user-select: text;
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
            "
            >参观时间</strong
          ></span
        >
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <span
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            font-size: 20px;
          "
          ><strong
            style="
              box-sizing: border-box;
              user-select: text;
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
            "
            ><br
              style="
                box-sizing: border-box;
                user-select: text;
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
              " /></strong
        ></span>
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        12月10日： 13:00-19:00&nbsp; &nbsp;专业观众
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <br
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
          "
        />
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        12月11日： 10:00-19:00&nbsp; &nbsp;专业观众、社会观众
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <br
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
          "
        />
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        12月12日： 10:00-18:00&nbsp; &nbsp;专业观众、社会观众
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <br
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
          "
        />
      </p>
      <p
        style="
          vertical-align: inherit;
          box-sizing: border-box;
          margin: 0px;
          user-select: text;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          padding: 0px;
          min-height: 1em;
          color: rgb(62, 62, 62);
          font-family: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB',
            'Microsoft YaHei', 'Apple Color Emoji', 'Emoji Symbols Font',
            'Segoe UI Symbol', Arial, sans-serif;
          font-size: 16px;
          text-align: justify;
          text-decoration-thickness: initial;
        "
        align="justify"
      >
        <strong
          style="
            box-sizing: border-box;
            user-select: text;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
          "
          >展会地点</strong
        >：澳门威尼斯人金光会展（L1层）（澳門望德聖母灣大馬路，路氹金光大道）
      </p>
      <section class="_135editor" data-role="paragraph">
        <p style="vertical-align: inherit">
          <br />
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "jiaotong",
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
</style>

<template>
  <div>
    <van-nav-bar
      :title="meeting_name"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <div
        class="rich_media_content"
        id="js_content"
        style="visibility: visible"
      >
        <section style="box-sizing: border-box; font-size: 16px">
          <section
            style="
              margin-top: 10px;
              margin-right: 0%;
              margin-left: 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section style="color: rgb(62, 62, 62); box-sizing: border-box">
              <p style="white-space: normal; box-sizing: border-box">
                <span style="font-size: 17px; box-sizing: border-box"
                  ><strong style="box-sizing: border-box"
                    >澳门-内地推介会&nbsp; 12月10日</strong
                  ></span
                >
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                中国中医药(澳门)品牌展览会&nbsp; &nbsp;活动区
              </p>
            </section>
          </section>
          <section
            style="margin: 10px 0%; box-sizing: border-box"
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="box-sizing: border-box" powered-by="xiumi.us">
            <p style="white-space: normal; box-sizing: border-box">
              15:30-15:45
            </p>
            <p style="white-space: normal; box-sizing: border-box">主持人：</p>
            <p style="white-space: normal; box-sizing: border-box">
              柳燕副主任，中国医药保健品进出口商会中药部
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              主持人介绍与会嘉宾
            </p>
            <p style="white-space: normal; box-sizing: border-box">致开幕辞:</p>
            <p style="white-space: normal; box-sizing: border-box">
              澳门方代表
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              内地方代表
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              15:45-16:05
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              澳门中药法律制度、中医药行业发展现状
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              澳门协会代表
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              16:05-16:15
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              中医药粤港澳大湾区融合发展
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              于志斌主任，中国医药保健品进出口商会
            </p>
          </section>
          <section
            style="
              text-align: right;
              justify-content: flex-end;
              margin: 10px 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                width: 44%;
                vertical-align: top;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  margin-top: 0.5em;
                  margin-bottom: 0.5em;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    background-color: rgb(62, 62, 62);
                    height: 1px;
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="box-sizing: border-box" powered-by="xiumi.us">
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
          </section>
          <section
            style="
              margin-top: 10px;
              margin-right: 0%;
              margin-left: 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section style="color: rgb(62, 62, 62); box-sizing: border-box">
              <p style="white-space: normal; box-sizing: border-box">
                <span style="font-size: 17px; box-sizing: border-box"
                  ><strong style="box-sizing: border-box"
                    >山东中医药健康服务（澳门）推介会&nbsp; 12月10日</strong
                  ></span
                >
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                中国中医药(澳门)品牌展览会&nbsp; &nbsp;活动区
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                主办单位：
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                山东省商务厅、商务部外贸发展事务局
              </p>
            </section>
          </section>
          <section
            style="margin: 10px 0%; box-sizing: border-box"
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="box-sizing: border-box" powered-by="xiumi.us">
            <p style="white-space: normal; box-sizing: border-box">
              16:15-16:20
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              主持人介绍参会嘉宾
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              嘉宾致辞：
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吕伟副厅长，山东省商务厅
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              16:20-16:50
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              园区及产品推介：
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              济南片区中医药文化贸易平台推介
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              南粤科技园推介
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              企业产品推介：东阿阿胶，宏济堂，同程尚品
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              合作项目签约&nbsp;&nbsp;
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              山东省商业集团有限公司山东省药学科学院、澳门大学、山东大学进行合作项目签约，合作共建「中药糖工程与检测技术联合实验室」，开展中药糖类药物与健康产品研究、检测服务与产业转化
            </p>
          </section>
          <section
            style="
              text-align: right;
              justify-content: flex-end;
              margin: 10px 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                width: 44%;
                vertical-align: top;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  margin-top: 0.5em;
                  margin-bottom: 0.5em;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    background-color: rgb(62, 62, 62);
                    height: 1px;
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="box-sizing: border-box" powered-by="xiumi.us">
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
          </section>
          <section
            style="
              margin-top: 10px;
              margin-right: 0%;
              margin-left: 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section style="color: rgb(62, 62, 62); box-sizing: border-box">
              <p style="white-space: normal; box-sizing: border-box">
                <span style="font-size: 17px; box-sizing: border-box"
                  ><strong style="box-sizing: border-box"
                    >吉澳中医药健康产业暨经贸合作推介会&nbsp; 12月10日</strong
                  ></span
                >
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                中国中医药(澳门)品牌展览会&nbsp; &nbsp;Z18展位
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                主办单位：
              </p>
              <p style="white-space: normal; box-sizing: border-box">
                吉林省工业和信息化厅、中国国际贸易促进委员会吉林省委员会、吉林省商务厅
              </p>
            </section>
          </section>
          <section
            style="margin: 10px 0%; box-sizing: border-box"
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: top;
                width: 25px;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  text-align: left;
                  justify-content: flex-start;
                  margin-right: 0%;
                  margin-left: 0%;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(237, 230, 221);
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="box-sizing: border-box" powered-by="xiumi.us">
            <p style="white-space: normal; box-sizing: border-box">
              16:50-16:55
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              嘉宾致辞：
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吉林省贸促会副会长孙仕政主持会议
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吉林省工信厅副厅长穆可桢介绍产业情况
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              16:55-17:30
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              园区及企业推介：
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吉林省敦化市医药产业园区
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吉林省长白山人参有限公司
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              长春人民医药集团公司
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              敖东世航药业股份有限公司
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              宝利祥蜂业股份有限公司
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              延边宝枫生物科技有限公司
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              <br style="box-sizing: border-box" />
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              合作项目签约
            </p>
            <p style="white-space: normal; box-sizing: border-box">
              吉林省长白山人参产业集团与澳门中华民族医药产业协会在「长白山人参」品牌及吉林省北药等相关产品的渠道建设方面进行合作。敖东世航药业股份有限公司与澳门运通集团有限公司在中药材饮片销售方面进行项目合作。
            </p>
          </section>
          <section
            style="
              text-align: right;
              justify-content: flex-end;
              margin: 10px 0%;
              box-sizing: border-box;
            "
            powered-by="xiumi.us"
          >
            <section
              style="
                display: inline-block;
                width: 44%;
                vertical-align: top;
                height: auto;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  margin-top: 0.5em;
                  margin-bottom: 0.5em;
                  box-sizing: border-box;
                "
                powered-by="xiumi.us"
              >
                <section
                  style="
                    background-color: rgb(62, 62, 62);
                    height: 1px;
                    box-sizing: border-box;
                  "
                >
                  <section>
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <p><br /></p>
      </div>
    </div>
    <div class="mabtn">
      <van-button round block type="primary" @click="attend"
        >立即报名</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "h1",
  data() {
    return {
      meeting_id: "1",
      meeting_name: "中国中医药(澳门)品牌展览会",
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: "huodong",
        params: {},
      });
    },
    attend() {
      this.$router.push({
        name: "AttendMeeting",
        query: {
          meeting_id: this.meeting_id,
          meeting_name: this.meeting_name,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
.mabtn {
  text-align: center;
  padding: 60px;
}
</style>

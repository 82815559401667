import request from '@/utils/request'

export function getExpoInfoByHost(url) {
    return request({
      url: '/api/admin/weizhan/getExpoInfoByHost',
      method: 'get',
      params: { url }
    })
  }

export function getExpoInfo(ex_id) {
  return request({
    url: '/api/admin/weizhan/getExpoInfo',
    method: 'get',
    params: { ex_id }
  })
}
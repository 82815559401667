<template>
  <div>
    <van-nav-bar
      title="现场报名签到"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-cell-group name="baseinfo" title="填写信息">

        <van-field v-model="name" name="name"
          required 
          label="姓名" 
          placeholder="请输入姓名"  
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field v-model="company" name="company"
          required 
          label="公司/机构" 
          placeholder="请输入公司/机构名称全称"  
          :rules="[{ required: true, message: '请输入公司/机构名称全称' }]"
        />
        <van-field v-model="zhiwu"  name="zhiwu"
          required
          label="职务" 
          placeholder="请输入职务"  
          :rules="[{ required: true, message: '请输入职务' }]"
        />
        <van-field v-model="shouji" name="shouji"
          required
          type="tel" 
          label="手机号" 
          placeholder="请输入手机号"  
          :rules="[{ required: true, pattern:/[1][3-9]\d{9}$|([6|9])\d{7}$|[0][9]\d{8}$|6\d{5}$/, message: '请正确输入手机号码' }]"
        />
        <!-- <van-field v-model="email"  name="email"
          required
          label="邮箱" 
          placeholder="请输入邮箱"  
          :rules="[{ required: true, pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入邮箱'}]"
        /> -->
        <!-- <van-field v-model="diqu" name="diqu"
          required 
          label="地区" 
          placeholder="请选省/市/区"  
          readonly
          clickable
          :rules="[{ required: true, message: '请选省/市/区' }]"
          right-icon="arrow"
          @click="showDiquPicker = true"
        /> -->
      </van-cell-group>
      <div style="margin: 60px;">
        <van-button round block type="primary" native-type="submit">提交信息</van-button>
      </div>
    </van-form>
    <van-popup v-model="showDiquPicker" round position="bottom">
      <van-area 
        title="请选择地区" 
        :area-list="areaList" 
        value="110101"
        @confirm="onDiquConfirm"
        @cancel="showDiquPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Dialog } from 'vant';

export default {
  name: 'baoming',
  data() {
    return {
      meeting_id: '',
      meeting_name: '',
      isFirstEnter: false,
      chenghu: '',
      showChenghuPicker: false,
      name: '',
      company: '',
      department: '',
      zhiwu: '',
      zhiwei: '',
      youzheng: '',
      areaList: areaList,
      diqu: '',
      showDiquPicker: false,
      areaCode: '',
      dizhi: '',
      dianhua: '',
      shouji: '',
      email: '',
      yuyan: '',
      showYuyanPicker: false,
      active: 0,
      xingqu:[],
      yewuleibie:[],
      page:'',
      file: ''
    };
  },
  created() {
    this.isFirstEnter=true;
    this.meeting_id=this.$route.query.meeting_id
    this.meeting_name=this.$route.query.meeting_name
  },
  beforeRouteEnter(to, from, next) {
  //从以下页面进入时候不刷新
      if(from.name=='AttendConfirm' || from.name=='AttendConfirm'){   
          to.meta.isBack=true;
      }
      next();
  },
  activated() {
      if(!this.$route.meta.isBack || this.isFirstEnter){
          //  刷新时候执行
          // layer.load(2);
          //this.initData()    执行方法
      }
      this.$route.meta.isBack=false
      this.isFirstEnter=false;
  }, 
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onDiquConfirm(value) {
      this.diqu = "";
      this.dizhi = "";
      for(let item of value){
          if (this.diqu != "") {
            this.diqu += "/"
          }

          this.diqu += item.name
          this.dizhi += item.name
      }
      
      this.areaCode = value[2]["code"];
      this.showDiquPicker = false;
    },
    onSubmit(values) {
      var _this = this
      let meeting_info = { "meeting_id": _this.meeting_id, "meeting_name": _this.meeting_name, "hexiao_type":_this.hexiao_type}
      let data = { ...meeting_info, ...values };
      _this.$axios.post('/api/baoming', data)
      .then((res) => {
        if (res.data.code == "0") {
          _this.$router.push({
            name: "SignSuccess",
            params:{}
          })
        }else {
          Dialog({ message: res.data.message });
        }
      })
      .catch((error) => { // 请求失败处理
        Dialog({ message: error });
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <div>
    <van-nav-bar
      title="我的"
      left-text="首页"
      left-arrow
      @click-left="back"
    />
    <div class="userInfo">
      <van-row>
        <van-col span="4"></van-col>
        <van-col span="4"><van-icon name="user-o" size="40" /></van-col>
        <van-col span="16">
          <div v-if="!phone">
            <span class="loginTxt" @click="login()">登 录</span>
          </div>
          <div v-else>
            <span class="userPhone">{{phone}}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="context">
      <van-grid :column-num="2">
        <van-grid-item icon="orders-o" text="订单" @click="onOrders" />
        <van-grid-item icon="qr" text="签到码" @click="onQr" />
      </van-grid>
      <div class="logoutBtn">
        <van-button type="default" block @click="logout">退出登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      phone: '',
      ex_id: ''
    };
  },
  created() {
    this.phone = localStorage.getItem('phone')
    this.ex_id = this.$route.query.ex_id
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home",
        query: {ex_id: this.ex_id},
      });
    },
    logout() {
      localStorage.removeItem("phone")
      localStorage.removeItem("user_id")
      localStorage.removeItem("openid")
    },
    login() {
      this.$router.push({ path: '/wx/login', query: {redirect:'/wx/my?ex_id=' + this.ex_id} })
    },
    onOrders() {
      this.$router.push({ path: '/wx/myOrders', query: {ex_id: this.ex_id} })
    },
    onQr() {
      this.$router.push({ path: '/wx/myQR', query: {ex_id: this.ex_id} })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
.logoutBtn {
  margin: 40px;
}
.userInfo {
  margin-top: 30px;
  margin-bottom: 30px;
}
.loginTxt {
  font-size: 20px;
  line-height: 40px;
  margin-left: 16px;
  color: dodgerblue;
}

.userPhone {
  font-size: 20px;
  line-height: 40px;
  margin-left: 16px;
}

</style>

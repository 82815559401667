<template>
  <div>
    <van-nav-bar
      title="同期活动"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <div class="box" v-for="(mt, index) in meetinglist" :key="index" @click="openUrl(mt.meeting_url)">
        <van-row type="flex" justify="space-between">
          <van-col span="16"><div class="title">{{mt.meeting_name}}</div></van-col>
          <van-col class="vancol" span="8"><div class="time">{{mt.meeting_time}}</div></van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getMeetings } from '@/api/meeting'
export default {
  name: "mtList",
  data() {
    return {
      meetinglist: [],
      ex_id:0
    };
  },
  created() {
    var _this = this;
    getMeetings({'meeting_type':'上架','ex_id':this.ex_id, 'meeting_name':''}).then((res) => {
      _this.meetinglist = res.data;
    })
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home",
        params: {},
      });
    },
    openUrl(url){
      window.open(url)

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
.box {
  border: 1px solid;
  border-radius: 10px;
  padding: 16px 12px;
  margin: 4px;
  border-color: darkgrey;  
}
.title {
  color: #1989fa;
}
.time {
  color:darkgrey;
}
.vancol {
  display:flex;
  align-items:center;
  justify-content: right;
}
</style>

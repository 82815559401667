<template>
  <div>
    <van-nav-bar
      title="报名参加"
      left-text="首页"
      left-arrow
      @click-left="onClickLeft"
      :right-text="rightText"
      @click-right="showShare = true"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>同行人报名</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-form @submit="onSubmit">
      <van-cell-group name="baseinfo" title="填写资料">
        <van-field
          v-model="company"
          name="company"
          required
          label="公司/机构"
          placeholder="请输入公司/机构名称全称"
          :rules="[{ required: true, message: '请输入公司/机构名称全称' }]"
        />

        <van-field
          v-model="name"
          name="name"
          required
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />

        <van-field
          v-model="zhiwei"
          name="zhiwei"
          required
          label="职位"
          placeholder="请输入职位"
          :rules="[{ required: true, message: '请输入职位' }]"
        />

        <van-field
          v-model="shouji"
          name="shouji"
          required
          type="tel"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[
            {
              required: true,
              pattern:
                /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/,
              message: '请正确输入手机号码',
            },
            {
              validator: ckShouji,
              message: '该手机号已经报名注册过，请选择其他手机号',
            },
          ]"
        />

        <van-field
          v-model="email"
          name="email"
          required
          label="邮箱"
          placeholder="请输入邮箱"
          :rules="[
            {
              required: true,
              pattern:
                /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
              message: '请输入邮箱',
            },
            {
              validator: ckEmail,
              message: '该邮箱已经报名注册过，请选择其他邮箱报名。',
            },
          ]"
        />

        <van-field
          name="piao"
          v-model="piao"
          required
          label="票种类"
          placeholder="请选择票种类"
          readonly
          clickable
          :rules="[{ required: true, message: '请选择票种类' }]"
        />
        <div class="piaoInfo">
          <p>
            可参与会议，专题论坛及展览区，不提供大会期间餐饮服务，注册完成后经组委会审核后发送成功报名信息。
          </p>
        </div>
      </van-cell-group>
      <van-cell-group title="公司/机构业务（限选1～3项）">
        <van-field
          name="yewuleibieGroup"
          label="业务类别"
          required
          v
          :rules="[{ required: true, message: '请选择' }]"
        >
          <template #input>
            <van-checkbox-group v-model="yewuleibie" :max="3">
              <van-checkbox name="0" shape="square">biotech</van-checkbox>
              <van-checkbox name="1" shape="square">Pharma</van-checkbox>
              <van-checkbox name="2" shape="square"
                >院校/科研机构/医疗机构</van-checkbox
              >
              <van-checkbox name="3" shape="square">CRO/CMO/CDMO</van-checkbox>
              <van-checkbox name="4" shape="square"
                >原辅料/试剂/耗材/仪器/设备/冷链/系统</van-checkbox
              >
              <van-checkbox name="5" shape="square"
                >政府部门/商协会/国际组织</van-checkbox
              >
              <van-checkbox name="6" shape="square"
                >数据/咨询/律所/投融资机构</van-checkbox
              >
              <van-checkbox name="7" shape="square">媒体</van-checkbox>
              <van-checkbox name="8" shape="square"
                >社会观众及其他</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          name="xingquGroup"
          label="感兴趣领域"
          required
          :rules="[{ required: true, message: '请选择' }]"
        >
          <template #input>
            <van-checkbox-group v-model="xingqu" :max="3">
              <van-checkbox name="0" shape="square">小分子药物</van-checkbox>
              <van-checkbox name="1" shape="square">抗体药物</van-checkbox>
              <van-checkbox name="2" shape="square">ADC药物</van-checkbox>
              <van-checkbox name="3" shape="square">细胞治疗</van-checkbox>
              <van-checkbox name="4" shape="square">基因治疗</van-checkbox>
              <van-checkbox name="5" shape="square">新型疫苗</van-checkbox>
              <van-checkbox name="6" shape="square">mRNA</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
      </van-cell-group>

      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form>
    <van-popup v-model="showChenghuPicker" round position="bottom">
      <van-picker
        title="票种类"
        show-toolbar
        :columns="piaos"
        @confirm="onChenghuConfirm"
        @cancel="showChenghuPicker = false"
      />
    </van-popup>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import { checkShouji, checkEmail } from "@/api/zhuce";
import { getSignature } from "@/api/user";
import wx from "weixin-js-sdk";

export default {
  name: "AttendMeeting",
  data() {
    return {
      result: ["a"],
      meeting_id: "",
      meeting_name: "",
      isFirstEnter: false,
      piao: "参会票",
      piaos: ["参会票"],
      showChenghuPicker: false,
      name: "",
      company: "",
      department: "",
      zhiwu: "",
      zhiwei: "",
      dianhua: "",
      shouji: "",
      email: "",
      yuyan: "",
      showYuyanPicker: false,
      active: 0,
      xingqu: [],
      yewuleibie: [],
      page: "",
      partner_id: "",
      sub_user_id: "",
      ex_id: "",
      showShare: false,
      options: [{ name: "复制链接", icon: "link" }],
      shareURL: "",
      brand: '',
      rightText: ''

    };
  },
  created() {
    window.scrollTo(0, 0);
    this.isFirstEnter = true;
    this.ex_id = this.$route.query.ex_id;
    this.partner_id = this.$route.query.partner_id;
    this.sub_user_id = this.$route.query.sub_user_id;

    //调取缓存内容
    this.piao = "参会票"
    this.name = localStorage.getItem("name");
    this.company = localStorage.getItem("company");
    this.zhiwei = localStorage.getItem("zhiwei");
    this.shouji = localStorage.getItem("shouji");
    this.email = localStorage.getItem("email");
    
    if (localStorage.getItem("xingqu")) {
      this.xingqu = localStorage.getItem("xingqu").split(",");
    }
    if (localStorage.getItem("yewuleibie")) {
      this.yewuleibie = localStorage.getItem("yewuleibie").split(",");
    }

    // 引用mobile-detect文件
    var MobileDetect = require('mobile-detect');
    var md = new MobileDetect(navigator.userAgent);
    // 品牌
    this.brand = md.mobile();
    if (this.brand == null) {
      this.brand = "电脑PC"
      this.rightText = "分享"
    }
    this.shareURL =
      location.protocol + "//" + location.host + this.$route.path + "?";
    this.shareURL += "sub_user_id=" + localStorage.getItem("user_id");
    if (this.partner_id) {
      this.shareURL += "&partner_id=" + this.partner_id;
    }

    let ua = navigator.userAgent.toLowerCase(); //判断是否是微信端
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.getWxShare();
    }
  },
  //路由跳转回来的时候,
  beforeRouteEnter(to, from, next) {
    //从以下页面进入时候不刷新
    if (from.name == "AttendConfirm" || from.name == "AttendConfirm") {
      to.meta.isBack = true;
    }
    // this.chenghu=localStorage.getItem('chenghu');
    next();
  },
  //路由跳转的时候
  beforeRouteLeave(to, from, next) {
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      //  刷新时候执行
      // layer.load(2);
      //this.initData()    执行方法
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  methods: {
    onClickLeft() {
      this.$router.push("/wx/home?ex_id=" + "1");
    },
    onClickRight() {
      Toast("按钮");
    },
    onChenghuConfirm(value) {
      this.piao = value;
      this.showChenghuPicker = false;
    },
    onDiquConfirm(value) {
      this.diqu = "";
      this.dizhi = "";
      for (let item of value) {
        if (this.diqu != "") {
          this.diqu += "/";
        }

        this.diqu += item.name;
        this.dizhi += item.name;
      }

      this.areaCode = value[2]["code"];
      this.showDiquPicker = false;
    },
    onSubmit(values) {
      let sub_info = {
        sub_user_id: this.sub_user_id,
        partner_id: this.partner_id,
        ex_id: this.ex_id,
      };
      let data = { ...sub_info, ...values };
      localStorage.setItem("piao", this.piao);
      localStorage.setItem("name", this.name);
      localStorage.setItem("company", this.company);
      localStorage.setItem("zhiwei", this.zhiwei);
      localStorage.setItem("shouji", this.shouji);
      localStorage.setItem("email", this.email);
      localStorage.setItem("xingqu", this.xingqu);
      localStorage.setItem("yewuleibie", this.yewuleibie);
      this.$router.push({
        name: "zhuceAdd", //这个path就是你在router/index.js里边配置的路径
        params: data,
      });
    },
    onYuyanConfirm(value) {
      this.yuyan = value;
      this.showYuyanPicker = false;
      window.scrollTo(0, 0);
    },
    getCode() {
      // 非静默授权，第一次有弹框
      const code = this.getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      if (code == null || code === "") {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.sv.APP_ID +
          "&redirect_uri=" +
          encodeURIComponent(this.sv.LOCAL) +
          "&response_type=code&scope=snsapi_base&state=" +
          this.page +
          "#wechat_redirect";
      } else {
        this.getOpenId(code); //把code传给后台获取用户信息
      }
    },
    getOpenId(code) {
      // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
      let _this = this;
      this.$ajax
        .post(
          this.$apiList.isRegist,
          {
            code: code,
            appid: this.sv.APP_ID,
            secret: this.sv.SECRET,
            hosnum: this.sv.HOSNUM,
            page: this.page,
          },
          true,
          {}
        )
        .then(
          function (data) {
            let _data = data.data;
            console.log(_data);
            //如果是没有注册过的用户进入到注册页面//210990198808097896
            if (_data.status === -1) {
              _this.$router.push(
                "/about?openid=" + _data.openid + "&page=" + _this.page
              );
            }
            //如果是注册过的用户(0-his,1-体检)
            else if (_data.status === "0") {
              _this.$router.push("/his?openid=" + _data.openid);
            } else if (_data.status === "1") {
              _this.$router.push("/amedical?openid=" + _data.openid);
            }
          },
          function (err) {
            console.log(err);
          }
        );
    },
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    showPiaoInfo() {
      Dialog.alert({
        title: "说明",
        messageAlign: "left",
        message:
          "参会票：\n此通道仅为药企、监管机构及科研院校填写，不提供大会期间餐饮服务。注册成功后经组委会审核后发送成功注册信息",
      }).then(() => {
        // on close
      });
    },
    onSelect() {
      var shareURL =
        location.protocol + "//" + location.host + this.$route.path + "?";
      shareURL += "sub_user_id=" + localStorage.getItem("user_id");
      if (this.partner_id) {
        shareURL += "&partner_id=" + this.partner_id;
      }
      let that = this;
      this.$copyText(shareURL).then(
        function () {
          Toast("复制成功");
          that.showShare = false;
        },
        function () {
          Toast("复制失败");
          that.showShare = false;
        }
      );
    },
    ckShouji(val) {
      var data = { shouji: val };
      return new Promise((resolve) => {
        resolve(
          checkShouji(data).then((res) => {
            console.log(res.data.length);

            if (res.data.length > 0) {
              console.log("false");
              return false;
            } else {
              console.log("true");
              return true;
            }
          })
        );
      });
    },
    ckEmail(val) {
      var data = { email: val };
      return new Promise((resolve) => {
        resolve(
          checkEmail(data).then((res) => {
            console.log(res.data.length);

            if (res.data.length > 0) {
              console.log("false");
              return false;
            } else {
              console.log("true");
              return true;
            }
          })
        );
      });
    },
    getWxShare() {
      let url = window.location.href; //http://newdrug.cphi-china.cn/wx/hezuo?sub_user_id=null
      var that = this;
      getSignature(url)
        .then((res) => {
          if (res.code == 10000) {
            let wxInfo = JSON.parse(res.data);
            console.log(wxInfo);
            wx.config({
              debug: false, // 是否开启调试模式
              appId: wxInfo.appid, //appid
              timestamp: Number(wxInfo.timestamp), // 时间戳
              nonceStr: wxInfo.noncestr, // 随机字符串
              signature: wxInfo.signature, // 签名
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 需要使用的JS接口列表
            });
            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: "观众注册", // 分享标题
                desc: "2022新药开发与合作高峰论坛", // 分享描述
                link: that.shareURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://newdrug.cphi-china.cn/img/share.jpeg", // 分享图标
                success: function () {
                  console.log("成功");
                },
                cancel: function () {
                  console.log("取消");
                },
              });
              wx.updateTimelineShareData({
                title: "观众注册", // 分享标题
                desc: "2022新药开发与合作高峰论坛", // 分享描述
                link: that.shareURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://newdrug.cphi-china.cn/img/share.jpeg", // 分享图标
                success: function () {
                  console.log("成功");
                },
                cancel: function () {
                  console.log("取消");
                },
              });
            });
            wx.error(function (res) {
              console.log(res.errMsg);
            });
          }
        })
        .catch();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.piaoInfo {
  font-size: 14px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: grey;
  line-height: 16px;
}
</style>

//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';
//第三方库需要use一下才能用
Vue.use(VueRouter)

import AttendMeeting  from './page/AttendMeeting.vue';
import AttendTongxing  from './page/AttendTongxing.vue';
import AttendConfirm  from './page/AttendConfirm.vue';
import AttendSuccess  from './page/AttendSuccess.vue';
import Sign  from './page/Sign.vue';
import SignSuccess  from './page/SignSuccess.vue';
import Home from './page/wx/Home.vue';
import Home1 from './page/wx/Home1.vue';
import content  from './page/wx/content.vue';
import mt  from './page/wx/mt.vue';
import mtList  from './page/wx/mtList.vue';
import jiaotong  from './page/wx/jiaotong.vue';
import lianxi  from './page/wx/lianxi.vue';
import xuzhi  from './page/wx/xuzhi.vue';
import jieshao  from './page/wx/jieshao.vue';
import mingdan  from './page/wx/mingdan.vue';
import huodong  from './page/wx/huodong.vue';
import baoming  from './page/baoming.vue';

import File  from './page/File.vue';
import ApplyLive  from './page/ApplyLive.vue';

import h1  from './page/wx/huiyi/h1.vue';
import h2  from './page/wx/huiyi/h2.vue';
import h3  from './page/wx/huiyi/h3.vue';
import h4  from './page/wx/huiyi/h4.vue';
import login  from './page/wx/login.vue';
import hezuo  from './page/wx/hezuo/hezuo.vue';
import hezuoConfirm  from './page/wx/hezuo/hezuoConfirm.vue';
import hezuoSuccess  from './page/wx/hezuo/hezuoSuccess.vue';
import zhuce  from './page/wx/zhuce/zhuce.vue';
import zhuceAdd  from './page/wx/zhuce/zhuceAdd.vue';
import zhuceTongxing  from './page/wx/zhuce/zhuceTongxing.vue';
import zhuceSuccess  from './page/wx/zhuce/zhuceSuccess.vue';

import my  from './page/wx/my.vue';
import myOrders  from './page/wx/myOrders.vue';
import myQR  from './page/wx/myQR.vue';
import signinQrcode  from './page/wx/signin/signinQrcode.vue';

//定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {
        path:'/page/AttendMeeting',
        component:AttendMeeting,
        name:'AttendMeeting',
        meta: {
            title: "会议报名",
            requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
            keepAlive: true, //此组件不需要被缓存
        }
    },
    {path:'/page/AttendTongxing',component:AttendTongxing, name:'AttendTongxing',meta: {
        title: "是否为同事登记"
    }},
    {path:'/page/AttendConfirm',component:AttendConfirm, name:'AttendConfirm',meta: {
        title: "信息确认"
    }},
    {path:'/page/AttendSuccess',component:AttendSuccess, name:'AttendSuccess',meta: {
        title: "报名成功"
    }},
    {path:"/page/baoming",component:baoming, name:'baoming',meta: {
        title: "会议签到"
    }},
    {path:"/page/Sign",component:Sign, name:'Sign',meta: {
        title: "会议签到"
    }},
    {path:"/page/SignSuccess",component:SignSuccess, name:'SignSuccess',meta: {
        title: "会议签到"
    }},
    {path:"/page/File",component:File, name:'File',meta: {
        title: "文件下载申请"
    }},
    {path:"/page/ApplyLive",component:ApplyLive, name:'ApplyLive',meta: {
        title: "直播观看申请"
    }},
    {path:"/wx/jiaotong",component:jiaotong, name:'jiaotong',meta: {
        title: "交通线路"
    }},
    {path:"/wx/home",component:Home, name:'Home',meta: {
        title: ""
    }},
    {path:"/wx/home1",component:Home1, name:'Home1',meta: {
        title: ""
    }},
    {path:"/wx/mt",component:mt, name:'mt',meta: {
        title: ""
    }},
    {path:"/wx/mtList",component:mtList, name:'mtList',meta: {
        title: ""
    }},
    {path:"/wx/content",component:content, name:'content',meta: {
        title: ""
    }},
    {path:"/wx/lianxi",component:lianxi, name:'lianxi',meta: {
        title: "联系我们"
    }},
    {path:"/wx/xuzhi",component:xuzhi, name:'xuzhi',meta: {
        title: "观展须知"
    }},
    {path:"/wx/jieshao",component:jieshao, name:'jieshao',meta: {
        title: "展会介绍"
    }},
    {path:"/wx/mingdan",component:mingdan, name:'mingdan',meta: {
        title: "展商名单"
    }},
    {path:"/wx/huodong",component:huodong, name:'huodong',meta: {
        title: "同期活动"
    }},
    {path:"/wx/huiyi/h1",component:h1, name:'h1',meta: {
        title: "同期活动"
    }},
    {path:"/wx/huiyi/h2",component:h2, name:'h2',meta: {
        title: "同期活动"
    }},
    {path:"/wx/huiyi/h3",component:h3, name:'h3',meta: {
        title: "同期活动"
    }},
    {path:"/wx/huiyi/h4",component:h4, name:'h4',meta: {
        title: "同期活动"
    }},
    {path:'/wx/login',component:login, name:'login',meta: {
        title: "登录"
    }},
    {path:'/wx/hezuo',component:hezuo, name:'hezuo',meta: {
        title: "合作项目",
        requireAuth: true
    }},
    {path:'/wx/hezuoConfirm',component:hezuoConfirm, name:'hezuoConfirm',meta: {
        title: "合作项目",
        requireAuth: true
    }},
    {path:'/wx/hezuoSuccess',component:hezuoSuccess, name:'hezuoSuccess',meta: {
        title: "合作项目",
        requireAuth: true
    }},
    {path:'/wx/zhuce',component:zhuce, name:'zhuce',meta: {
        title: "观众注册",
        requireAuth: true
    }},
    {path:'/wx/zhuceAdd',component:zhuceAdd, name:'zhuceAdd',meta: {
        title: "观众注册",
        requireAuth: true
    }},
    {path:'/wx/zhuceTongxing',component:zhuceTongxing, name:'zhuceTongxing',meta: {
        title: "观众注册",
        requireAuth: true
    }},
    {path:'/wx/zhuceSuccess',component:zhuceSuccess, name:'zhuceSuccess',meta: {
        title: "观众注册",
        requireAuth: true
    }},
    {path:'/wx/my',component:my, name:'my',meta: {
        title: "我的"
    }},
    {path:'/wx/myOrders',component:myOrders, name:'myOrders',meta: {
        title: "我的订单",
        requireAuth: true
    }},
    {path:'/wx/myQR',component:myQR, name:'myQR',meta: {
        title: "我的订单",
        requireAuth: true
    }},
    {path:'/wx/signinQrcode',component:signinQrcode, name:'signinQrcode',meta: {
        title: "签到",
        requireAuth: true
    }},
]

//实例化VueRouter并将routes添加进去
const router=new VueRouter({
    mode: "history",
    //ES6简写，等于routes：routes
    routes
});


  
//抛出这个这个实例对象方便外部读取以及访问
export default router
<template>
  <div>
    <van-nav-bar
      title="交通线路"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <h1>澳门威尼斯人</h1>
      <h3>The Venetian Macao</h3>
      <p><van-icon name="location-o" />望德聖母灣大馬路s/n號</p>
      <p><van-icon name="location-o" />s/n Estrada da Baia de Nossa Senhora da Esperanca, Macao</p>
      <br/>
      <h3>地图导航</h3>
      <div>
        <div class="btn"><van-button plain hairline block round type="primary" @click="baidu">百度地图</van-button></div>
        <div class="btn"><van-button plain hairline block round type="primary" @click="google">谷歌地图</van-button></div>
        <div class="btn"><van-button plain hairline block round type="primary" @click="tengxun">腾讯地图</van-button></div>
        <div class="btn"><van-button plain hairline block round type="primary" @click="gaode">高德地图</van-button></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'jiaotong',
  data() {
    return {
       images: [
        '/img/山东中医药健康服务推介会.jpg',
        '/img/中华医药产业发展主论坛.jpg',
        '/img/中医药女性健康论坛.jpg',
        '/img/中医针灸国际合作及产业发展论坛.jpg'
      ],
    };
  },
  methods: {
    baidu() {
      window.open("https://api.map.baidu.com/marker?location=22.149833,113.571294&title=澳门威尼斯人&output=html");
    },
    tengxun() {
      window.open("https://router.map.qq.com/short?l=65e87ef3a6dfaa10e5dcc113125182a2");
    },
    google() {
      window.open("https://goo.gl/maps/mDVTCRxkasCzeqNm6");
    },
    gaode() {
      window.open("https://m.amap.com/search/mapview/__p=B073D0M4FP,22.145351,113.566661,%E6%BE%B3%E9%97%A8%E5%A8%81%E5%B0%BC%E6%96%AF%E4%BA%BA,%E6%BE%B3%E9%97%A8%E7%89%B9%E5%88%AB%E8%A1%8C%E6%94%BF%E5%8C%BA%E8%B7%AF%E5%87%BC%E5%A1%AB%E6%B5%B7%E5%8C%BA%E8%B7%AF%E5%87%BC%E9%87%91%E5%85%89%E5%A4%A7%E9%81%93%E8%BF%9E%E8%B4%AF%E5%85%AC%E8%B7%AF,820000&src=pc&callnative=1&callapp=0&poiid=B073D0M4FP")
    },
    back() {
      this.$router.go(-1);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
.btn {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

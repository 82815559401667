<template>
  <div>
    <van-nav-bar
      title="我的订单"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div>
      <van-cell-group>
        <van-cell center v-for="item in orderList" :key="item" :title="item.name" :label="item.create_time" is-link @click="onOrders(item.order_id)" >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #default>
            <van-tag v-if="item.order_state == 0" type="primary">待审核</van-tag>
            <van-tag v-else-if="item.order_state == 1" type="success">审核通过</van-tag>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getOrderList } from '@/api/zhuce'

export default {
  name: "myOrders",
  data() {
    return {
      phone: '',
      user_id: '',
      orderList: [],
      ex_id: ''
    };
  },
  created() {
    this.user_id = localStorage.getItem('user_id')
    this.getOrderList()
    this.ex_id = this.$route.query.ex_id
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home",
        query: {ex_id:this.ex_id},
      });
    },
    onOrders(order_id) {
      this.$router.push({
            name: "zhuceSuccess",
            query:{ 'order_id': order_id
            }
          })
    },
    getOrderList(){
      var data = {'user_id': this.user_id}
      getOrderList(data).then((res) => {
        this.orderList = res.data
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
.logoutBtn {
  margin: 40px;
}
.userInfo {
  margin-top: 30px;
  margin-bottom: 30px;
}
.loginTxt {
  font-size: 20px;
  line-height: 40px;
  margin-left: 16px;
  color: dodgerblue;
}

.userPhone {
  font-size: 20px;
  line-height: 40px;
  margin-left: 16px;
}

</style>

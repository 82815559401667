<template>
  <div>
    <van-nav-bar
      title="同期活动"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="context">
      <van-image
        width="100%"
        v-for="(mt, index) in meetinglist"
        :key="index"
        :src="mt.meeting_img"
        @click="openUrl(mt.meeting_url)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "mt",
  data() {
    return {
      meetinglist: [],
    };
  },
  created() {
    var _this = this;

    _this.$axios
      .get("/api/admin/meeting/getMeetings?meeting_name=&meeting_type=上架")
      .then((res) => {
        console.log(res.data);
        _this.meetinglist = res.data.data;
      });
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home",
        params: {},
      });
    },
    openUrl(url){
      window.open(url)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  margin: 18px;
}
</style>

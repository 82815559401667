<template>
  <div>
    <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item widt="100%" v-for="(banner, index) in banners" :key="index">
        <van-image width="100%" :src="banner.banner_img" @click="bannerClick(banner.banner_url)" />
      </van-swipe-item>
    </van-swipe>
    <div class="grid">
      <div v-if="ex_id == 0">
        <van-grid :column-num="3" :gutter="9" clickable>

          <van-row>
            <van-col span="8"><van-image class="centitem" src="/img/01.png" @click="shenpage(1)"/></van-col>
            <van-col span="8"><van-image class="centitem" src="/img/02.png" @click="shenpage(2)" /></van-col>
            <van-col span="8"><van-image class="centitem" src="/img/03.png" @click="shenpage(3)" /></van-col>
          </van-row>
          
          <van-row>
            <van-col span="8"><van-image class="centitem" src="/img/04.png" @click="shenpage(4)" /></van-col>
            <van-col span="8"><van-image class="centitem" src="/img/05.png" @click="shenpage(5)" /></van-col>
            <van-col span="8"><van-image class="centitem" src="/img/06.png" @click="shenpage(6)" /></van-col>
          </van-row>
          <van-row>
            <van-col span="24"><van-image class="centitem" src="/img/07.png" @click="shenpage(7)" /></van-col>
          </van-row>
          
          <van-row>
            <van-col span="8"><van-image class="centitem" src="/img/08.png" @click="shenpage(8)" /></van-col>
            <van-col span="16"><van-image class="centitem" src="/img/09.png" @click="shenpage(9)" /></van-col>
          </van-row>

        </van-grid >
           
      </div>
      <div v-else>
        <van-grid :column-num="3" :gutter="10" clickable>
          <van-grid-item v-for="(gongge, index) in gongges" :key="index" :icon="gongge.gg_img" :text="gongge.gg_name" :url="gongge.gg_url"  />
        </van-grid >
      </div>
      <div class="bottom"></div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o" @click="onMy" >我的</van-tabbar-item>
    </van-tabbar>
  </div>
  
</template>

<script>
import { getSignature } from "@/api/user";
import wx from "weixin-js-sdk";

export default {
  name: 'Home',
  data() {
    return {
      banners: [],
      gongges: [],
      ex_id: '',
      sc_id: '',
      so_id: '',
      shareURL: "",
    }
  },
  created() {
    this.ex_id = this.$route.query.ex_id
    this.sc_id = this.$route.query.sc_id
    this.so_id = this.$route.query.so_id
    if(!this.ex_id) {
      this.ex_id = 0
    }
    this.getExpo(this.ex_id)
    this.getBanner(this.ex_id)
    this.getGongge(this.ex_id)


    this.shareURL = location.protocol + "//" + location.host + this.$route.path + "?"
    this.shareURL += "&ex_id=" + this.ex_id
    this.shareURL += "&sub_user_id=" + localStorage.getItem("user_id")
    this.getWxShare()
  },
  methods: {
    getExpo(ex_id) {
      var _this = this
      _this.$axios.get('/api/admin/weizhan/getExpoInfo', {params:{ex_id: ex_id}})
      .then((res) => {
        console.log(res.data.data)
        document.title = res.data.data.data.ex_name
        _this.shareDesc = res.data.data.data.ex_name
      });
    },
    getBanner(ex_id) {
      var _this = this
      _this.$axios.get('/api/admin/weizhan/getBanner', {params:{type:0,ex_id: ex_id}})
      .then((res) => {
        
        _this.banners = res.data.data
      });
    },
    getGongge(ex_id) {
      var _this = this

      _this.$axios.get('/api/admin/weizhan/getGongge', {params:{type:0, ex_id: ex_id}})
      .then((res) => {
        _this.gongges = res.data.data
        console.log(_this.gongges)
      });
    },
    onMy(){
      this.$router.push({
        name: "my",
        query:{ex_id: this.ex_id}
      })
    },
    bannerClick(url) {
      if(url) {
        window.open(url);
      }
    },
    getWxShare() {
      let url = window.location.href; //http://newdrug.cphi-china.cn/wx/hezuo?sub_user_id=null
      var that = this;
      getSignature(url)
        .then((res) => {
          if (res.code == 10000) {
            let wxInfo = JSON.parse(res.data);
            console.log(wxInfo);
            wx.config({
              debug: false, // 是否开启调试模式
              appId: wxInfo.appid, //appid
              timestamp: Number(wxInfo.timestamp), // 时间戳
              nonceStr: wxInfo.noncestr, // 随机字符串
              signature: wxInfo.signature, // 签名
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 需要使用的JS接口列表
            });
            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: this.shareDesc, // 分享标题
                desc: this.shareDesc, // 分享描述
                link: that.shareURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://newdrug.cphi-china.cn/img/share.jpeg", // 分享图标
                success: function () {
                  console.log("成功");
                },
                cancel: function () {
                  console.log("取消");
                },
              });
              wx.updateTimelineShareData({
                title: this.shareDesc, // 分享标题
                desc: this.shareDesc, // 分享描述
                link: that.shareURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://newdrug.cphi-china.cn/img/share.jpeg", // 分享图标
                success: function () {
                  console.log("成功");
                },
                cancel: function () {
                  console.log("取消");
                },
              });
            });
            wx.error(function (res) {
              console.log(res.errMsg);
            });
          }
        })
        .catch();
    },
    shenpage(index) {
      switch(index){
        case 1:
          window.open("/wx/content?tuwen_id=17")//展会介绍
          break
        case 2:
          window.open("/wx/content?tuwen_id=22")//展馆展区
          break
        case 3:
          window.open("/wx/content?tuwen_id=18")//展商名单
          break

        case 4:
          window.open("/wx/content?tuwen_id=25")//资料下载
          break
        case 5:
          window.open("/page/AttendMeeting")//观众预约参观
          break
        case 6:
          window.open("/wx/content?tuwen_id=23")//交通线路
          break

        case 7:
          window.open("/wx/mtList")
          break

        case 8:
          window.open("/wx/content?tuwen_id=21")
          break
        case 9:
          window.open("/wx/content?tuwen_id=20")
          break
        case 10:
          window.open("https://live.kingsmacao.com/live/tvchat-1508147818?ver=638017753256772839&shauid=r7xN8u-LpbERWs6eJaGimA**&sharetstamp=1666149730877")
          break
        case 11:
          window.open("/wx/content?tuwen_id=35")
          break
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.swipe {
  width: 100%;
}
.grid {
  margin-top: 10px;
  margin-right: 10px;
}
.myBtn {
  position: absolute;
  top:500px;
  right: 60px;
}
.rmhy {
  margin-top: 8px;
}

.spzb {
  margin-top: 8px;
  width: 65%;
}

.centitem {
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 2px;
}
.bottom {
  height: 80px;
  width: 100%;
  
}
</style>
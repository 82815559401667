<template>
  <div>
    <van-nav-bar
      title="扫码签到"
      left-text="首页"
      left-arrow
      @click-left="onClickLeft"
      :right-text="rightText"
      @click-right="showShare = true"
    />
    <div class="context">
      <div v-if="result">
        <p class="qr_name">{{qr_name}}</p>
        <div style="margin: 20px">
          <van-icon name="checked" color="#07c160" size="90" />
        </div>
        <p>签到成功</p>
        <p class="piao_name">{{piao_name}}</p>
        <p class="piao_company">{{piao_company}}</p>
        <p v-if="main_sign" style="">您以完成思享会签到</p>
      </div>
      <div v-else>
        <div style="margin: 20px">
          <van-icon name="question" color="ff976a" size="90" />
        </div>
        <div v-if="ex_id == '1'">
          <p>未找到相关注册信息，请注册.</p>
          <div>
            <van-button  type="primary" @click="onZhuce">立即注册</van-button>
          </div>
        </div>
        <div v-else-if="ex_id == '0'">
          <p>未找到相关信息，请填写.</p>
          <div>
            <van-form @submit="onSubmit">
            <van-field
              v-model="name"
              name="name"
              required
              label="姓名"
              placeholder="请输入姓名"
              :rules="[{ required: true, message: '请输入姓名' }]"
            />
            <van-field
              v-model="shouji"
              name="shouji"
              required
              type="tel"
              label="手机号"
              placeholder="请输入手机号"
              :disabled="inputShouji"
              :rules="[
                {
                  validator: ckShouji,
                  message: '该手机号已经报名注册过，请选择其他手机号',
                },
              ]"
            />
            <van-field v-model="nianling" name="nianling"
              required v
              :rules="[{ required: true, message: '请选择年龄' }]"
              label="年龄" 
              placeholder="请选择年龄"  
              readonly
              clickable
              @click="showYuyanPicker = true"
              right-icon="arrow"
            />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交信息</van-button>
          </div>
          <van-popup v-model="showYuyanPicker" round position="bottom">
            <van-picker
              title="年龄"
              show-toolbar
              :columns="nianlings"
              @confirm="onYuyanConfirm"
              @cancel="showYuyanPicker = false"
            />
          </van-popup>
        </van-form>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { signinQrcode } from "@/api/signin";
import { checkShouji, addTmpInfo } from '@/api/meeting'

export default {
  name: "AttendMeeting",
  data() {
    return {
      phone: '',
      qrcode_id: '',
      result: false,
      piao_name: '',
      piao_company: '',
      ex_id: '',
      qr_name: '',
      main_sign: false,
      company: '',
      name: '',
      zhiwei: '',
      shouji: localStorage.getItem('phone'),
      email: '',
      inputShouji: true,
      nianling: '',
      nianlings: ['18岁以下','18-35', '36-45', '46-60', '61及以上'],
      showYuyanPicker: false,
    };
  },
  created() {
    var that = this
    window.scrollTo(0, 0);
    this.qrcode_id = this.$route.query.id
    if (this.qrcode_id == 10 || this.qrcode_id == 11 || this.qrcode_id == 12) {
      this.inputShouji = false
    }
    this.phone = localStorage.getItem('phone')
    var data = {'qrcode_id': this.qrcode_id, 'phone':this.phone}
    signinQrcode(data).then((res) => {
      that.ex_id = res.data.ex_id
      if (res.data.upcode == '1') {
        that.result = true;
        that.piao_name = res.data.data.user_name
        that.piao_company = res.data.data.user_company
        that.qr_name = res.data.qr_name
        if(res.data.qr_name == '私享会签到' &&  res.data.main_cnt > 1) {
          this.main_sign = true
        }
      } else {
        that.result = false
      }
    })
  },
  methods: {
    onClickLeft() {
      // this.$router.push("/wx/home?ex_id=" + this.ex_id);
    },
    onZhuce(){
      this.$router.push("/wx/zhuce?ex_id=" + this.ex_id);
    },
    ckShouji(val) {
      var data = { 'shouji': val, 'ex_id': this.ex_id }
      return new Promise((resolve) => {
        resolve(
          checkShouji(data).then((res) => {
            console.log(res.data.cnt)
            if (res.data.cnt != 0) {
              return false;
            } else {
              return true;
            }
          })
        );
      });
    },
    onYuyanConfirm(value) {
      this.nianling = value;
      this.showYuyanPicker = false;
      window.scrollTo(0,0);
    },
    onSubmit(){
      var data = {
        "user_id": localStorage.getItem("user_id"),
        "ex_id": this.ex_id,
        "shouji": this.shouji,
        "user_name": this.name,
        "nianling": this.nianling,
      }

      addTmpInfo(data).then((res) => {
        if (res.code == 10000) {
          localStorage.setItem("phone", this.shouji)
          location.reload();
        }
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context {
  text-align: center;
}
.qr_name {
  font-size: 20px;
}
.piao_name {
  font-size: 20px;
  font-weight: bold;
}
</style>

<template>
  <div>
    <van-nav-bar
      title="报名参加"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>是否为同事登记</van-step>
      <van-step>资料确认</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-cell center title="是否需要为同事登记成为“中国中医药健康（澳门）品牌展览会">
        <template #right-icon>
            <van-switch v-model="addtongxing" @change="changeSwich" size="24" />
        </template>
    </van-cell>
    <van-form @submit="onSubmit">
    <div v-if="addtongxing">
      
      <van-cell-group inset v-for="(item,index) in tongxing" :key="index">
        <template #title>
            <div><span>添加同行</span><span class="delbutten"  @click="delTongxing(index)">删除</span></div>
        </template>

        <van-field name="chenghu" v-model="item.chenghu" 
          required 
          label="称呼" 
          placeholder="请选择称呼"  
          readonly
          clickable
          :rules="[{ required: true, message: '请选择称呼' }]"
          @click="onChenghuPicker(index)"
          right-icon="arrow"
        />

        <van-field v-model="item.name" name="name"
          required 
          label="姓名" 
          placeholder="请输入姓名"  
          :rules="[{ required: true, message: '请输入姓名' }]"
        />

        <van-field v-model="item.company" name="company"
          required 
          label="公司/机构" 
          placeholder="请输入公司/机构名称全称"  
          :rules="[{ required: true, message: '请输入公司/机构名称全称' }]"
        />

        <van-field name="zhiji" v-model="item.zhiji" 
          required 
          label="职级" 
          placeholder="请选职级"  
          readonly
          clickable
          :rules="[{ required: true, message: '请选职级' }]"
          @click="onZhijiPicker(index)"
          right-icon="arrow"
        />

        <van-field v-model="item.diqu" name="diqu"
          required 
          label="地区" 
          placeholder="请选省/市/区"  
          readonly
          clickable
          :rules="[{ required: true, message: '请选省/市/区' }]"
          right-icon="arrow"
          @click="onDiquPicker(index)"
        />

        <van-field v-model="item.dizhi"  name="dizhi"
          required
          label="地址" 
          placeholder="请输入地址"  
          :rules="[{ required: true, message: '请输入地址' }]"
        />

        <van-field v-model="item.dianhua" name="dianhua"
        required
          type="tel" 
          label="电话" 
          placeholder="请输入电话号吗"  
          :rules="[{ required: true, message: '请输入电话号吗' }]"
        />

        <van-field v-model="item.shouji" name="ckShouji"
        required
          type="tel" 
          label="手机号" 
          placeholder="请输入手机号"  
          :rules="[{ required: true, pattern:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-9,5-9]))[0-9]{8}$/, message: '请正确输入手机号码' },
          {required: true, validator: shoujiCheck, message: '一个手机号只能购买一张票' },
          { validator: ckShouji, message: '该手机号已经报名注册过，请选择其他手机号' }]"
        />

        <van-field v-model="item.email"  name="email"
          required
          label="邮箱" 
          placeholder="请输入邮箱"  
          :rules="[{ required: true, pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入邮箱'}]"
        />

       
      </van-cell-group>
      
      <div style="margin: px; text-align:center">
        <van-button round type="primary" @click="addTongxing()">继续添加</van-button>
      </div>
      
    </div>
    

      <div>
      <van-row>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="default" @click="onEdit">修改</van-button>
          </div>
        </van-col>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">下一步</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
    </van-form>
    <van-popup v-model="showChenghuPicker" round position="bottom">
        <van-picker
          title="称呼"
          show-toolbar
          :columns="chenghus"
          @confirm="onChenghuConfirm"
          @cancel="showChenghuPicker = false"
        />
      </van-popup>
      <van-popup v-model="showZhijiPicker" round position="bottom">
        <van-picker
          title="职级"
          show-toolbar
          :columns="zhijis"
          @confirm="onZhijiConfirm"
          @cancel="showZhijiPicker = false"
        />
      </van-popup>
      <van-popup v-model="showDiquPicker" round position="bottom">
        <van-area 
          title="请选择地区" 
          :area-list="areaList" 
          value="110101"
          @confirm="onDiquConfirm"
          @cancel="showDiquPicker = false"
        />
      </van-popup>

  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { checkShouji } from '@/api/meeting'

export default {
  name: 'AttendMeeting',
  data() {
    return {
      result: ['a'],
      meeting_id: '',
      meeting_name: '',
      isFirstEnter: false,
      chenghu: '',
      chenghus: ['先生','女士', '博士', '教授', '小姐'],
      showChenghuPicker: false,
      name: '',
      company: '',
      department: '',
      zhiwu: '',
      youzheng: '',
      areaList: areaList,
      diqu: '',
      showDiquPicker: false,
      areaCode: '',
      dizhi: '',
      dianhua: '',
      shouji: '',
      email: '',
      yuyan: '',
      showYuyanPicker: false,
      yuyans: ['广东话','普通话', '英语', '葡萄牙语', '其他'],
      zhiji: '',
      zhijis: ['董事/总裁/主席','总经理/高级副总/执行副总', '副总/总监', '经理', '顾问', '普通职员', '其他'],
      active: 1,
      xingqu:[],
      yewuleibie:[],
      page:'',
      hexiao_type: 0, //0不核销，1立即核销
      tongxing:[],
      showZhijiPicker: false,
      addtongxing: false,
      clickIndex: 0,
      ex_id: '0'
    };
  },
  created() {
    window.scrollTo(0,0);
    this.isFirstEnter=true;
    this.meeting_id=this.$route.query.meeting_id
    this.meeting_name=this.$route.query.meeting_name
    this.hexiao_type=this.$route.query.hexiao_type
    //调取缓存内容
    this.chenghu=localStorage.getItem('chenghu');
    this.name=localStorage.getItem('name');
    this.company=localStorage.getItem('company');
    this.department=localStorage.getItem('department');
    this.zhiwu=localStorage.getItem('zhiwu');
    this.zhiji=localStorage.getItem('zhiji');
    this.youzheng=localStorage.getItem('youzheng');
    this.diqu=localStorage.getItem('diqu');
    this.showDiquPicker=localStorage.getItem('showDiquPicker');
    this.dizhi=localStorage.getItem('dizhi');
    this.dianhua=localStorage.getItem('dianhua');
    this.shouji=localStorage.getItem('shouji');
    this.email=localStorage.getItem('email');
    this.yuyan=localStorage.getItem('yuyan');
    this.xingqu=localStorage.getItem('xingqu').split(',');
    this.yewuleibie=localStorage.getItem('yewuleibie').split(',');
    if (localStorage.getItem("tongxing")) {
      this.tongxing = JSON.parse(localStorage.getItem("tongxing"));
    }
    this.yuyan=localStorage.getItem('yuyan');
    this.addtongxing = JSON.parse(localStorage.getItem('addtongxing'))

  },
  //路由跳转回来的时候,
  beforeRouteEnter(to, from, next) {
  //从以下页面进入时候不刷新
      if(from.name=='AttendConfirm' || from.name=='AttendConfirm'){   
          to.meta.isBack=true;
      }
      // this.chenghu=localStorage.getItem('chenghu');
      next();
  },
  //路由跳转的时候
  beforeRouteLeave(to,from,next){
    
    next();
  },
    
  activated() {
      if(!this.$route.meta.isBack || this.isFirstEnter){
          //  刷新时候执行
          // layer.load(2);
          //this.initData()    执行方法
      }
      this.$route.meta.isBack=false
      this.isFirstEnter=false;
  }, 
  methods: {
    shoujiCheck(value){
      console.log(value)
      console.log(this.shouji)
      if (this.shouji == value) {
        return false
      }
      let cut = 0
      
      this.tongxing.forEach( function(item){
        if (item.shouji == value) {
          cut += 1
        }
      })
      console.log("cut"+cut)
      if (cut > 1) {
        return false
      }

      return true
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      Toast('按钮');
    },
    onChenghuConfirm(value) {
      this.tongxing[this.clickIndex].chenghu = value;
      this.showChenghuPicker = false;
    },
    onZhijiConfirm(value) {
      this.tongxing[this.clickIndex].zhiji = value;
      this.showZhijiPicker = false;
    },
    changeSwich(value) {
      console.log(value)
      if(value){
        console.log("this.tongxing.length"+this.tongxing.length)
        if( this.tongxing.length == 0 ){
          this.tongxing.push({
            "chenghu": '',
            "name": '',
            "company": this.company,
            "zhiji": '',
            "diqu": '',
            "dizhi": '',
            "dianhua": '',
            "shouji": '',
            "email": ''
          })
        }
      }
    },
    onDiquConfirm(value) {
      this.tongxing[this.clickIndex].diqu = "";
      this.tongxing[this.clickIndex].dizhi = "";
      for(let item of value){
          if (this.tongxing[this.clickIndex].diqu != "") {
            this.tongxing[this.clickIndex].diqu += "/"
          }

          this.tongxing[this.clickIndex].diqu += item.name
          this.tongxing[this.clickIndex].dizhi += item.name
      }
      
      // this.areaCode = value[2]["code"];
      this.showDiquPicker = false;
    },
    onSubmit(values) {
      console.log(values)
      let meeting_info = { "meeting_id": this.meeting_id, "meeting_name": this.meeting_name, "hexiao_type":this.hexiao_type}
      if (this.addtongxing) {
        localStorage.setItem('tongxing', JSON.stringify(this.tongxing));
      } else {
        localStorage.removeItem('tongxing');
      }
      localStorage.setItem('addtongxing',this.addtongxing)
      
      this.$router.push({
          name:"AttendConfirm",//这个path就是你在router/index.js里边配置的路径
          query: meeting_info
      })
    },
    onYuyanConfirm(value) {
      this.yuyan = value;
      this.showYuyanPicker = false;
      window.scrollTo(0,0);
    },
    getCode () { // 非静默授权，第一次有弹框
       const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
       if (code == null || code === '') {
         window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.sv.APP_ID+'&redirect_uri=' + encodeURIComponent(this.sv.LOCAL) + '&response_type=code&scope=snsapi_base&state='+this.page+'#wechat_redirect'
       } else {
         this.getOpenId(code) //把code传给后台获取用户信息
       }
     },
    getOpenId (code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
       let _this = this
       this.$ajax.post(this.$apiList.isRegist, {code: code,appid:this.sv.APP_ID,secret:this.sv.SECRET,hosnum:this.sv.HOSNUM,page:this.page}, true,{}).then(function (data) {
         let _data=data.data;
         console.log(_data)
         //如果是没有注册过的用户进入到注册页面//210990198808097896
         if (_data.status === -1 ) {
           _this.$router.push("/about?openid="+_data.openid+"&page="+_this.page);
         }
         //如果是注册过的用户(0-his,1-体检)
         else if(_data.status ==='0'){
           _this.$router.push("/his?openid="+_data.openid);
         }else if(_data.status==='1'){
           _this.$router.push("/amedical?openid="+_data.openid);
         }
       }, function (err) {
         console.log(err);
       });
     },
     getUrlParam(name) {
       let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
       let r = window.location.search.substr(1).match(reg);
       if (r != null) return unescape(r[2]);
       return null;
     },
     onNext(){

     },
     nextButton(){

     },
     onEdit(){
      this.$router.go(-1);
     },
     addTongxing(){
      this.tongxing.push({
        "chenghu": '',
        "name": '',
        "company": this.company,
        "zhiji": '',
        "diqu": '',
        "dizhi": '',
        "dianhua": '',
        "shouji": '',
        "email": ''
      })
     },
    onChenghuPicker(index){
      this.showChenghuPicker = true
      this.clickIndex = index
    },
    onZhijiPicker(index){
      this.showZhijiPicker = true
      this.clickIndex = index
    },
    onDiquPicker(index){
      this.showDiquPicker = true
      this.clickIndex = index
    },
    delTongxing(index){
      console.log(index)
      this.tongxing.splice(index,1)
    },
    ckShouji(val) {
      var data = { 'shouji': val, 'ex_id': this.ex_id }
      return new Promise((resolve) => {
        resolve(
          checkShouji(data).then((res) => {
            console.log(res.data.cnt)
            if (res.data.cnt != 0) {
              return false;
            } else {            
              return true;
            }
          })
        );
      });
    },
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.delbutten {
  margin-right: 10px;
  float: right;
  color:#1989fa;
}
</style>

<template>
  <div>
    <van-nav-bar
      title="报名参加"
      left-text="修改"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-steps :active="active">
      <van-step>资料填写</van-step>
      <van-step>资料确认</van-step>
      <van-step>报名完成</van-step>
    </van-steps>
    <van-cell-group name="baseinfo" title="">


      <van-field v-model="name" name="name"
        readonly
        label="姓名" 
      />


      <van-field v-model="shouji" name="shouji"
        readonly
        type="tel" 
        label="手机号" 
      />

      <van-field v-model="nianling" name="nianling"
        label="年龄" 
        readonly
      />
    </van-cell-group>
    
    <div>
      <van-row>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="default" @click="onEdit">修改</van-button>
          </div>
        </van-col>
        <van-col span="12">
          <div style="margin: 16px">
            <van-button round block type="primary" :disabled='nextButton' @click="onNext">下一步</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Dialog } from 'vant';
import { AttendMeeting } from '@/api/meeting'
import {nanoid} from  'nanoid'

export default {
  name: 'AttendConfirm',
  data() {
    return {
      meeting_id: '',
      meeting_name: '',
      chenghu: '',
      chenghus: ['先生','女士', '博士', '教授', '小姐'],
      showChenghuPicker: false,
      name: '',
      company: '',
      department: '',
      zhiwu: '',
      zhiji: '',
      youzheng: '',
      areaList: areaList,
      diqu: '',
      showDiquPicker: false,
      areaCode: '',
      dizhi: '',
      dianhua: '',
      shouji: '',
      email: '',
      nianling: '',
      showYuyanPicker: false,
      nianlings: ['18岁以下','18-35', '36-45', '46-60', '61及以上'],
      active: 1,
      coll: ['1','2'],
      xingqu:[],
      yewuleibie:[],
      yewuleibieTxt: ['中药生产企业','保健产品生产企业','中医药器械生产企业','中药、保健产品代理/批发商/分销商','中医药器械批发商代理/批发商/分销商','进出口商','商超、药店、专卖店','微商、电商微商、电商','康养/健康管理','医疗机构','政府部门','社会团体','研究机构/院校','使领馆、国际组织','海外驻华采购机构','媒体','社会观众','其他（请注明）'],
      xingquTxt: ['中药材、中药饮片、天然提取物','天然药物、复方制剂、民族药等','中药保健品','针灸、艾灸等中医器具','中医诊断/治疗/康复仪器','其他保健产品','其他（请注明）'],
      hexiao_type: 0, //0不核销，1立即核销
      nextButton: false,
      tongxing:[],
      
    };
  },
  created() {
    window.scrollTo(0,0);

    this.meeting_id=this.$route.query.meeting_id
    this.meeting_name=this.$route.query.meeting_name
    this.hexiao_type=this.$route.query.hexiao_type
    this.chenghu=localStorage.getItem('chenghu');
    this.name=localStorage.getItem('name');
    this.company=localStorage.getItem('company');
    this.department=localStorage.getItem('department');
    this.zhiwu=localStorage.getItem('zhiwu');
    this.zhiji=localStorage.getItem('zhiji');
    this.youzheng=localStorage.getItem('youzheng');
    this.diqu=localStorage.getItem('diqu');
    this.showDiquPicker=localStorage.getItem('showDiquPicker');
    this.dizhi=localStorage.getItem('dizhi');
    this.dianhua=localStorage.getItem('dianhua');
    this.shouji=localStorage.getItem('shouji');
    this.email=localStorage.getItem('email');
    this.nianling=localStorage.getItem('nianling');
    this.xingqu=localStorage.getItem('xingqu').split(',');
    this.yewuleibie=localStorage.getItem('yewuleibie').split(',');
    if (localStorage.getItem("tongxing")) {
      this.tongxing = JSON.parse(localStorage.getItem("tongxing"));
    }
    console.log(this.tongxing)
    this.addtongxing = JSON.parse(localStorage.getItem('addtongxing'))
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onNext() {
      var uuid = nanoid()
      let data = {
        "meeting_id":this.$route.query.meeting_id,
        "meeting_name":this.$route.query.meeting_name,
        "hexiao_type":this.$route.query.hexiao_type,
        "chenghu":localStorage.getItem('chenghu'),
        "name":localStorage.getItem('name'),
        "company":localStorage.getItem('company'),
        "department":localStorage.getItem('department'),
        "zhiwu":localStorage.getItem('zhiwu'),
        "zhiji":localStorage.getItem('zhiji'),
        "youzheng":localStorage.getItem('youzheng'),
        "diqu":localStorage.getItem('diqu'),
        "showDiquPicker": localStorage.getItem('showDiquPicker'),
        "dizhi": localStorage.getItem('dizhi'),
        "dianhua": localStorage.getItem('dianhua'),
        "shouji": localStorage.getItem('shouji'),
        "email": localStorage.getItem('email'),
        "nianling": localStorage.getItem('nianling'),
        "xingqu": localStorage.getItem('xingqu'),
        "yewuleibie": localStorage.getItem('yewuleibie'),
        "tongxing": localStorage.getItem("tongxing"),
        "user_id": localStorage.getItem("user_id"),
        "uuid": uuid
      }
      var _this = this
      this.nextButton = true
      AttendMeeting(data).then((res) => {
        if (res.code == "10000") {
          _this.nextButton = false
          localStorage.removeItem("tongxing")
          localStorage.removeItem("addtongxing")
          _this.$router.push({
            name: "AttendSuccess",
            params:{
              "hexiao_type": _this.hexiao_type,
              "meeting_id": _this.meeting_id,
              "meeting_name": _this.meeting_name
            }
          })
        }else {
          Dialog({ message: res.message });
        }
      })
    },
    onEdit() {
      this.$router.go(-1);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
import request from '@/utils/request'

export function AttendMeeting(data) {
  return request({
    url: '/api/wx/meeting/AttendMeeting',
    method: 'post',
    data: data
  })
}
export function getMeetings(data) {
  return request({
    url: '/api/admin/meeting/getMeetings',
    method: 'get',
    params: data
  })
}

export function checkShouji(data) {
  return request({
    url: '/api/wx/meeting/checkShouji',
    method: 'post',
    data: data
  })
}

export function getOrderList(data) {
  return request({
    url: '/api/wx/meeting/getOrderList',
    method: 'post',
    data: data
  })
}

export function sendMeetingJoin(data) {
  return request({
    url: '/api/wx/meeting/sendMeetingJoin',
    method: 'post',
    data: data
  })
}

export function addTmpInfo(data) {
  return request({
    url: '/api/wx/piao/addTmpInfo',
    method: 'post',
    data: data
  })
}

